@import '../../sassStyles/main.scss';
.learnmore_wrapper{
    display: flex;
    padding: 32px;
    align-items: center;
   
    align-self: stretch;
    background: linear-gradient(180deg, rgba(217, 251, 246, 0.50) 0%, #FFF 100%);
    width: 100%;
    justify-content: center;
}
.learnmore_heading{
    align-self: stretch;
    color: var(--Text-text-primary, #2B2C30);

    /* Headline/H5 */
    font-family: "IntelOne Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.25px;
}
.learnmore_subheading{
    color: var(--Text-text-secondary, #6A6D75);

    /* Regular/Title XS | Subtitle L (font-size-200) */
    font-family: "IntelOne Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
}

.learnmore_content_section {
    display: flex;
    padding: 2px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex: 1 0 0;

    .learnmore_text_section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
    }
}

.learnmore_text{
    align-self: stretch;
    color: var(--Text-text-secondary, #6A6D75);

    /* Regular/Title XS | Subtitle L (font-size-200) */
    font-family: "IntelOne Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
}

.learnmore_link {
    display: flex;
    align-items: center;
    gap: 8px;
    background: transparent;
    background-blend-mode: multiply;
    text-decoration: none !important;
    outline-color: var(--cds-spark-theme-link-primary);
    cursor: pointer;
    

    .learnmore_link_text {
        color: var(--Aqua-Aqua-Shade-1, #0F9B8F);

        /* Link/Large */
        font-family: "IntelOne Text";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
        
    }

    .learnmore_link_icon {
        width: 16px;
        height: 16px;
        line-height: inherit;
        color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
        fill: var(--Transparent-Transparent, rgba(255, 255, 255, 0.00));
        background-blend-mode: multiply;
        
    }
}

.learnmore_button.spark-button.spark-button-action{
    background-color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
}

.learnmore-container {
    width: 100%;
    justify-content: center;
    max-width: 99rem;
    display: flex;
    align-items: center;
    gap: 32px;

}