.cardLayout_filter_chipset {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
  padding-bottom: 20px;
  .selected-filter-tags {
    margin: 0px;
    max-inline-size: 20rem;
  }
  .clear-filter {
    color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
    /* Link/Small */
    font-family: "IntelOne Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  /* unvisited link */
    a.clear-filter:link,
    /* visited link */
    a.clear-filter:visited,
    /* mouse over link */
    a.clear-filter:hover,    
    /* selected link */
    a.clear-filter:active {
        text-decoration: none;
        cursor: pointer;
        color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
    }
  .selected-filter-tags > span {
    color: var(--Tag-Gray-tag-color-gray, #161616);
    font-family: 'IntelOne Text';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.32px;
  }
}
.card-result-count{
    padding-top: 20px;
    padding-bottom: 20px;
}
.content_pagination {
    float: inline-end;
    margin-top: 15px;
}


