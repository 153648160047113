.EdgeInsidghtDownload {
    width: 100%;
    padding-left: 61px;
}

.EdgeInsidghtDownload .downloadList {
    width: 100%;
}

.downloadingHeader {
    color: #262626;
    font-family: "Intel One Medium";
    font-size: 36px;
    line-height: 46px;
}

.downloadingTitle {
    color: #252525;
    font-family: "Intel One Medium";
    font-size: 20px;
    line-height: 21px;
    margin: 18px 0px;
}

.getStartedWithMain {
    display: inline-flex;
    font: bold 16px/22px "Intel Clear";
    letter-spacing: 0px;
    color: #262626;

}

.getStartedWrapper{
    padding-bottom: 24px;
}

.packageDetailsHeader {
    font: 16px/22px "Intel Clear";
    letter-spacing: 0px;
    color: #262626;
    opacity: 1;
    margin-top: 20px;
}

.packageDetailsHeader>ul {
    color: #252525;
    font-size: 16px;
}

.packageDetailsContainer {
    margin-top: 27px;
    margin-right: 50px;
    font: 16px/22px "Intel Clear";
    letter-spacing: 0px;
    color: #262626;
}

.packageDetailsContainer ul {
    padding-inline-start: 0 !important;
    list-style-type: none;
    margin-top: 25px;
    margin-bottom: 25px;
}

.packageDetailsContainer li {
    color: "#252525";
    font-size: 16px;
}

.packageDetailsContent {
    color: #252525;
    font-size: 16px;
}

.iconStyle {
    width: 13px !important;
    height: 13px !important;
    margin-left: 3px !important;
}

.downloadInstruction {
    color: #262626;
    font-family: "Intel Clear";
    font-size: 16px;
    line-height: 23px;
    line-height: 21px;
    margin: 18px 0px 36px 0;
}

.padding15 {
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
}

.productkey {
    text-align: left;
    font: 12px/16px Intel Clear;
    letter-spacing: 0px;
    color: #525252;
    opacity: 1;
    padding-left: 21px;
    padding-bottom: 8px;
}

.font20 {
    font-size: 20px;
    font-family: "Intel One Medium";
}

.fontclear {
    font-family: "Intel Clear";
    color: #262626;
    font-size: 16px;
    line-height: 22px;
}

.downloadicon {
    width: 40px;
    height: 40px;
}

.modulesWrapper {
    font-family: "Intel Clear";
    color: #262626;
    font-size: 14px;
    line-height: 18px;
}

.productKeyWrapper {
    padding: 12px 39px 11px 19px;
    display: inline-flex;
    width: 596px;
    border: 1px solid #c9cace;
    border-radius: 2px;
    opacity: 1;
    height: 40px;
    align-items: center;
}

#ODTextBox {
    display: inline;
    vertical-align: top;
}

.CopyBtnDiv {
    display: inline-flex;
    position: absolute;
    padding: 4px 4px 4px 520px;
}

.TextOverFlowHidden {
    word-break: break-all;
}

.CopyBtnDiv {
    opacity: 0;
}

.CopyBtnDiv:hover {
    opacity: 1;
}

.copyBtn, .copyBtn:focus {
    width: 72px;
    height: 32px;
    background: #0068b5 !important;
    border-radius: 2px !important;
    opacity: 1;
    font-size: 16px !important;
    color: #fff !important;
    text-transform: none !important;
    line-height: 0px;
}

.copyBtn:focus {
    box-shadow: none !important;
}