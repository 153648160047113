.LoaderBackground {
  background-color:transparent;
  width: 50%;
  height: 100%;
  margin: auto;
  position: absolute;
  z-index: 100;
  left: 0;
  right:0;
}

@keyframes ldio-xxxc6nh3ik {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldio-xxxc6nh3ik div {
  position: absolute;
  width: 62px;
  height: 62px;
  border: 8px solid #00aeef;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-xxxc6nh3ik div {
  animation: ldio-xxxc6nh3ik 1s linear infinite;
  top: 50px;
  left: 50px
}

.loadingio-spinner-rolling-jd6tmu9tmn {
  width: 64px;
  height: 64px;
  display: inline-block;
  overflow: hidden;
  background:transparent;
  left: 50%;
  top: 40%;
  position: absolute;
}

.ldio-xxxc6nh3ik {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.64);
  backface-visibility: hidden;
  transform-origin: 0 0;
}

.ldio-xxxc6nh3ik div {
  box-sizing: content-box;
}