.ESHMain{
  width: auto;
  margin: auto;
  /* background-color: #f3f3f3 !important; */
@font-face {
  font-family: "Intel Clear Pro Bold";
  src: local("Intel Clear Pro Bold"), url(../fonts/IntelClear_Bd.ttf) format("truetype");
}

@font-face {
  font-family: "Intel Clear Bold";
  src: local("Intel Clear Bold"), url(../fonts/IntelClear_Rg.ttf) format("truetype");
}

@font-face {
  font-family: "Intel Clear Pro";
  src: local("Intel Clear Pro "), url(../fonts/IntelClear_Bd.ttf) format("truetype");
}

@font-face {
  font-family: "Intel Clear";
  src: local("Intel Clear"), url(../fonts/IntelClear_Rg.ttf) format("truetype");
}

@font-face {
  font-family: "Intel One Bold";
  src: local("Intel One Bold"), url(../fonts/intelone-display-bold.woff) format("woff");
}

@font-face {
  font-family: "Intel One Light";
  src: local("Intel One Light"), url(../fonts/intelone-display-light.woff) format("woff");
}

@font-face {
  font-family: "Intel One Medium";
  src: local("Intel One Medium"), url(../fonts/intelone-display-medium.woff) format("woff");
}

@font-face {
  font-family: "Intel One Regular";
  src: local("Intel One Regular"), url(../fonts/intelone-display-regular.woff) format("woff");
}

html {
  font-family: "Intel Clear";
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  background-color: #fff !important;
  font-family: "Intel Clear" !important;
  font-size: 0.875rem !important;
  box-sizing: inherit !important;
  letter-spacing: 0.01071em !important;
}

.ESHWrapper {
  min-height: 400px;
  position: relative;
  overflow-x: hidden;
  background: var(--Layer-layer-01, #F9F9F9);
}

.ESHWrapperWithBackgroundColor {
  min-height: 400px;
  margin-top: 24px;
  position: relative;
  background-color: #f3f3f3 !important;
}

.pos80 {
  position: relative;
  bottom: 80px;
}

.pos0 {
  position: relative;
  bottom: 0px;
}

.secondaryHeading {
  font-size: 20px;
  line-height: 26px;
  font-family: "Intel One Regular";
  color: #262626;
}

.martop0 {
  margin-top: 0% !important;
}

.margin0 {
  margin: 0% !important;
  margin-top: 36px !important;
}

.ESHWrapperCustom {
  min-height: 400px;
  background-color: #fff;
  margin: 0px 2%;
  margin-top: 36px;
  position: relative;
  bottom: 40px;
}

.root {
  min-width: 1366px;
  overflow: hidden;
  font-family: "Intel Clear";
}

@media only screen and (max-width: 480px) {
  html {
    font-size: 100%;
  }
}

select.form-control {
  font-size: 16px;
  padding: 0.375rem 1rem !important;
}

.dropdown a {
  color: #ffffff !important;
  font-family: "Intel Clear" !important;
  font-size: 11px !important;
  line-height: 14px !important;
}

.dropdown-menu {
  width: 100%;
}

.dropdown .dropdown-menu a {
  color: #0071c5 !important;
}

.dropdown {
  border: 0px !important;
  border-radius: 0 !important;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.separator::before, .separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #f3f3f3;
}

.separator::before {
  margin-right: 0.25em;
}

.separator::after {
  margin-left: 0.25em;
}

.btn-outline-warning:active, .btn-outline-warning:hover {
  background-color: #fff !important;
  box-shadow: none !important;
}

.pull-left {
  max-width: 50%;
}

.form-control:focus {
  box-shadow: none !important;
}

.ingredientContainer::-webkit-scrollbar {
  width: 10px;
}

.ingredientContainer::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 20px;
  border-radius: 10px;
}

.ingredientContainer::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d6d6d;
}

@-webkit-keyframes slideInFromLeft {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideInFromLeft {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInFromRight {
  0% {
    transform: translateX(900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideInFromRight {
  0% {
    transform: translateX(900px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(900px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-900px);
  }
}

@-moz-keyframes slideOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-900px);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-900px);
  }
}

@-webkit-keyframes slideInFromBottom {
  0% {
    transform: translateY(900px);
  }

  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes slideInFromBottom {
  0% {
    transform: translateY(900px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(900px);
  }

  100% {
    transform: translateY(0);
  }
}

a:link, a:visited, a:focus {
  outline: none;
  text-decoration: none;
}

a:hover {
  text-decoration: none !important;
}

.infoIconStyle {
  color: #0056b3 !important;
  font-size: 20px;
}

.infoIconStyle:hover {
  color: #0056b3;
  background-color: #f3f3f3;
  cursor: pointer;
  border-radius: 5px;
}

.popover {
  min-width: 464px;
  max-height: 290px;
  background-color: #525252 !important;
  border: 0 !important;
  box-shadow: 0px 2px 3px #1c283683;
  border-radius: 0 !important;
  opacity: 1;
}

.popup-selection {
  max-height: 520px;
  background-color: #fff !important;
  border-top: 4px solid #8bae46 !important;
  border-radius: 0 !important;
}

.popup-selection .popover-body {
  background-color: #fff !important;
  max-height: 300px;
  color: #707070 !important;
  width: 530px;
  margin-right: 24px;
  height: 100% !important;
}

.popup-selection .downloadBtnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.popup-selection .popover-header {
  background-color: #fff !important;
  color: #000 !important;
}

.popup-selection .popover-body .MuiFormGroup-root {
  flex-wrap: inherit !important;
}

.popover-header {
  background-color: #525252 !important;
  border-bottom: 0 !important;
  color: #fff !important;
  padding: 24px 0.75rem 10px 36px !important;
}

#popover-selction-info .popover-header {
  padding: 16px 8px 8px 26px !important;
  font: normal normal 16px/20px "IntelOne Display" !important;
}

#popover-selction-info .popover-body {
  padding: 0 0 0 26px !important;
  padding: 0px 0 10px 26px !important;
  font-size: 12px;
  line-height: 16px;
  height: auto;
  max-height: 160px;
}

#popover-selction-info .arrow {
  margin-left: 15px !important;
}

#popover-selction-info>.arrow:after {
  border-top-color: #525252 !important;
}

.popover-body {
  background-color: #525252 !important;
  border-bottom: 0 !important;
  color: #fff !important;
  height: 192px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 439px;
  padding-left: 36px !important;
  margin-bottom: 15px;
}

.popover-footer {
  background-color: #000;
  height: 64px;
  padding: 12px 10px;
  padding-left: 36px;
}

.footerContainer {
  background-color: #f3f3f3;
}

.arrow {
  margin: -10px !important;
}

.bs-popover-top>.arrow {
  bottom: calc(-0.5rem - 7px) !important;
}

.bs-popover-top>.arrow:after {
  border-top-color: #000 !important;
}

.bs-popover-top>.arrow:before {
  border-width: 0.85rem 0.7rem 0 !important;
}

.bs-popover-top>.arrow:after {
  border-width: 0.85rem 0.7rem 0 !important;
}

.bs-popover-left>.arrow:after {
  border-left-color: #000 !important;
}

.bs-popover-top>.arrow:after {
  border-top-color: #000 !important;
}

.popover-body::-webkit-scrollbar {
  width: 6px;
}

.popup-selection .popover-body::-webkit-scrollbar-track {
  background-color: #fff;
}

.popup-selection .popover-body::-webkit-scrollbar-thumb {
  background: rgb(34, 34, 34);
  border-radius: 10px;
}

.popup-selection .popover-body::-webkit-scrollbar-thumb:hover {
  background: rgb(34, 34, 34);
}

.popover-body::-webkit-scrollbar-track {
  background: #000;
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

.popover-body::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
}

/* Handle on hover */

.popover-body::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

#popover-container .popover-body {
  height: auto;
  width: auto;
  padding: 10px !important;
  margin: 0 !important;
}

#popover-container {
  color: #262626 !important;
}

#popover-container>.arrow {
  left: -444px !important
}

#popover-container>.arrow:after {
  border-top-color: #525252 !important;
}

.closeBtn {
  position: absolute;
  right: 30px;
  top: 15px;
  width: 12px;
  height: 15px;
  opacity: 1;
}

.closeBtn:before, .closeBtn:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 15px;
  width: 1.5px;
  background-color: #fff;
}

.closeBtn:before {
  transform: rotate(45deg);
}

.closeBtn:after {
  transform: rotate(-45deg);
}

.mega-panel {
  left: 0;
  position: absolute;
  width: 100%;
  box-shadow: 0px 1px 0px #00000029;
  opacity: 1;
  z-index: 1;
}

.opacity0 {
  opacity: 0;
}

.opacity1 {
  opacity: 1;
}

.mega-panel.in {
  visibility: inherit !important;
}

.mega-panel {
  top: 70px;
}

.mega-panel {
  background: #fff;
}

.rwd .global-nav-redesign .intel-navbar {
  background: #fff;
  border-bottom: 1px solid #d7d7d7;
  color: #555;
  height: 70px;
  position: relative;
  z-index: 1030;
}

.mega-panel .mega-panel-body {
  padding: 34px 33px 8px;
}

.mega-panel-wrap {
  border-bottom: 1px solid #d7d7d7;
  border-top: 1px solid #d7d7d7;
}

.nav-link-icon a:hover {
  text-decoration: none;
}

.nav-link-icon a {
  color: #0071c5;
  position: relative;
  top: 3px;
}

.nav-link-icon {
  font-family: "Intel Clear";
  color: #0071c5;
  background: transparent;
  display: inline-block;
  border: 0;
  white-space: nowrap;
  padding: 20.5px 5px;
  margin: 0 7px;
}

.nav-link-icon:focus {
  border-left: 2px solid #2c71c5;
  border-right: 2px solid #2c71c5;
  border-top: 2px solid #2c71c5;
  border-bottom: 4px solid #00aeef;
  border-radius: 2px 2px 0px 0px;
  opacity: 1;
}

.nav-link-icon:focus {
  outline: none;
}

.nav-link-icon:hover {
  color: #003c71;
}

.nav-12 {
  font-family: "Intel Clear Bold";
  font-size: 18px;
  line-height: 23px;
  font-weight: bold;
  padding-bottom: 8px;
  border-bottom: 1px solid #d7d7d7;
  margin-bottom: 8px;
  margin-left: 0;
  padding-top: 0;
  color: #707070;
}

.nav-14 {
  font-family: "Intel Clear";
  font-size: 14px;
  color: #0071c5;
  margin: 0;
  text-decoration: none;
  line-height: 2;
  cursor: pointer;
}

.lang-option {
  line-height: 2;
}

.lang-option a:hover {
  text-decoration: none;
}

.header-nav-14 {
  font-family: "Intel Clear Bold";
  font-size: 14px;
  color: #0071c5;
  margin: 0;
  text-decoration: none;
  line-height: 2;
  font-weight: bold;
  border-bottom: 1px solid #d7d7d7;
}

.lia-button-primary {
  font-family: "Intel Clear";
  color: #fff;
  background-color: #0071c5;
  border-color: #0071c5;
  border-radius: 3px;
  font-size: 14px;
  line-height: 17.5px;
  padding: 8px 16px;
  font-weight: 400;
}

.lia-button-primary:hover {
  font-family: "Intel Clear";
  background-color: #003c71;
  border-color: #003c71;
  color: #fff;
  text-decoration: none;
}

.panel-width {
  display: inline-flex;
  width: 20%;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.panel-region {
  font-family: "Intel Clear Bold";
  font-size: 14px;
  color: #707070;
  padding-bottom: 8px;
  border-bottom: 1px solid #d7d7d7;
}

.flex-content-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -16px;
}

.panel-item {
  width: 20%;
  padding: 0 16px;
  margin-bottom: 32px;
}

.nav-link-icon-lang {
  font-family: "Intel Clear";
  color: #0071c5;
  background: transparent;
  display: inline-block;
  border: 0;
  white-space: nowrap;
  padding-left: 0px;
  padding-right: 0px;
  margin: 0 7px;
}

.nav-link-icon-lang:focus {
  outline: none;
}

.in, .inprofile {
  display: block;
}

.collapse, .collapseprofile {
  display: none;
}

.recipeTitle {
  font-family: Intel Clear Pro;
  font-size: 36px;
  margin: 0;
  color: #252525;
  padding: 0px 0px 8px 0px;
}

.dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #0071c5;
}

.btn.dropdown-toggle {
  padding-right: 16px !important;
  padding-left: 24px !important;
}

.MuiRadio-root {
  color: #262626 !important;
}

.Mui-checked {
  color: #0068b5 !important;
}

.headerSticky {
  position: fixed;
  width: 100%;
  margin: auto;
  left: 0;
  background-color: #fff;
  top: 0;
  z-index: 10;
  max-height: 100px;
  overflow: hidden;
  transition: top 1s ease;
  border: 1px solid #eee;
  box-shadow: 0px 3px 6px #394f6229;
  opacity: 1;
}

.headerSticky.visible {
  top: 108px;
}

.MuiPopover-paper {
  background-color: #fff !important;
  border-top: 4px solid #8bae46 !important;
  border-radius: 0 !important;
}

#consumption_popover .MuiPopover-paper {
  padding: 10px !important;
  border: none !important;
  width: 368px !important;
  height: 48px !important;
  background: #525252 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 2px 3px #1C283683 !important;
  opacity: 1;
}

.popup-selection .popover-body {
  max-height: 300px;
  padding: 0 !important;
  padding-left: 36px !important;
  padding-right: 15px !important;
}

.ConfigWrapper {
  padding: 0 15px;
  margin-bottom: 24px;
}

.eloquaWrapper {
  padding-left: 32px;
  padding-right: 24px;
}

.eloquaWrapper .MuiCheckbox-root {
  padding: 2px !important;
}

.eloquaWrapper .MuiSvgIcon-root {
  width: 20px !important;
  height: 20px !important;
}

.MuiTypography-root {
  font-family: "Intel Clear" !important;
  font-size: 14px !important;
}

@media screen and (max-width: 1366px) {
  .MuiPopover-paper {
    background-color: #fff !important;
    border-top: 4px solid #8bae46 !important;
    border-radius: 0 !important;
  }

  .EdgeInsightDownloadCustommargin {
    margin-top: 17%;
    width: 93%;
    margin-left: 50px;
  }

  .EdgeInsightDownloadProgress, .ESHLicenseAgreementCustomStyle {
    position: relative;
    left: 50px;
    top: 130px;
    background-color: #f3f3f3 !important;
    width: 93% !important;
  }

  .ESHLicenseAgreementCustomStyle {
    left: 50px;
    background-color: #f3f3f3 !important;
    width: 93% !important;
    top: 129px;
    height: 86px;
    width: 93% !important;
  }

  .ESHLicenseAgreementHeaderCustomStyle {
    position: relative;
    top: 25px;
    left: 200px;
    font-size: 18px;
    font-weight: bold;
  }
}
}