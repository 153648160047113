@import '../../sassStyles/main.scss';
.explore_tile_wrapper{
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background: var(--Layer-layer-01, #F9F9F9);
}
.explore_title{
    color: var(--Text-text-primary, #2B2C30);
    /* Fixed heading styles/heading-04 */
    font-family: "IntelOne Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px; /* 128.571% */
}
.explore_tile{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;

    .explore_tile_icon {
        width: 32px;
        height: 32px;
    }
}
.explore_tile_title{
    align-self: stretch;
    color: var(--Text-text-primary, #2B2C30);
    text-align: center;
    /* Regular/Title XS | Subtitle L (font-size-200) */
    font-family: "IntelOne Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
}
.explore_tile_grid{
    display: flex;
    padding-top: 16px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    width: 100%;
    height: auto;
    align-content: flex-start;
    gap: 16px;
    flex-wrap: wrap;

    .cds--css-grid-column {
        --cds-grid-mode-start: var(--cds-grid-gutter-start);
        --cds-grid-mode-end: var(--cds-grid-gutter-end);
        margin-inline-start: 0px !important;
        margin-inline-end: 0px !important;
    }
}
.explore_topic_tile_wrapper{
    
    min-width: 200px;
    width: 100%;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    border: 1px solid var(--Border-border-subtle-00, #E9E9E9);
    background: var(--Layer-layer-02, #FFF);
    outline-color: var(--cds-spark-theme-link-primary);
    cursor: pointer;
 
    .explore_topic_tile_content {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex: 1 0 0;
        width: 100%;
        height: auto;
    }
   

}

