.serverunavilable2{
    display: flex;
    font-family: "Intel Clear";
    font-size: 14px;
    line-height: 20px;
    margin: 2.5em 0 0 1.5em;
    padding: 40px;
}

.serverunavilable2 h1{
    display: block;
    font-family: "Intel Clear Pro";
    color : rgba(0, 0, 0, 0, 0.6);
    
} 
.marginLeft20{
	margin-left:20px;
}