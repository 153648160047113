@import '../../sassStyles/main.scss';

.hero-banner-text {
    flex: 1 0 0;
    color: var(--Black-and-White-white, #FFF);

    /* Regular/Title M (font-size-400) */
    font-family: "IntelOne Display";
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    /* 133.333% */
    width: 100%;
    height: auto;

    @media (max-width: 767px) {
        font-size: 24px;
        line-height: 32px;
    }
}

.hero-banner-button {
    display: flex;
    width: auto;
    height: var(--Height, 40px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    background: --Transparent-Transparent;
    background-blend-mode: multiply;
    justify-self: flex-end;

    .hero-banner-button-content {
        display: flex;
        //padding: var(--v-padding, 10px) var(--h-padding, 16px);
        align-items: center;
        gap: var(--Gap, 8px);
        align-self: stretch;
        justify-content: flex-end;
        width: 100%;
        height: auto;

        @media (max-width: 430px) {
            justify-content: flex-start;
        }

        .hero-banner-button-content-text {
            color: var(--Background-background, #FFF);
            border: 1px solid var(--Background-background, #FFF);
            /* Button/Button Large */
            font-family: "IntelOne Text";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 125% */

        }

        .hero-banner-button-icon-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .hero-banner-button-icon {
                width: 16px;
                height: 16px;
                background: var(--Transparent-Transparent, #FFF);
                background-blend-mode: multiply;
            }

        }
    }


}

/* .cds--btn.cds--btn--secondary:hover:not(.cds--btn--disabled) {
    --cds-text-on-color: var(--cds-spark-button-primary-hover);
    border-color: var(--cds-spark-button-primary-hover);
    background-color: #16CFB1;
} */


.banner {
    width: 100%;
    height: 100%;
    padding: 32px;
    background: linear-gradient(131deg, rgba(162, 246, 232, 0.06) 0%, rgba(162, 246, 232, 0.30) 100%);
    border-top: 8px #38007F solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 32px;

    .banner-content {
        align-self: stretch;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 32px;

        .banner-icon {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            position: relative;
            width: 120px;
            height: 120px;
        }

        .banner-text {
            width: 884px;
            padding-top: 2px;
            padding-bottom: 2px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 12px;

            .text-content {
                align-self: stretch;
                height: 92px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 4px;

                .title {
                    color: #1C223B;
                    font-size: 24px;
                    font-family: 'IntelOne Display';
                    font-weight: 500;
                    line-height: 32px;
                    letter-spacing: 0.25px;
                    word-wrap: break-word;
                }

                .description {
                    align-self: stretch;
                    color: #676D84;
                    font-size: 20px;
                    font-family: 'IntelOne Display';
                    font-weight: 400;
                    line-height: 28px;
                    word-wrap: break-word;
                }
            }

            .learn-more-link {
                // background: white;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 8px;
                cursor: pointer;

                .learn-more-link-text {
                    color: #08746E;
                    font-size: 16px;
                    font-family: 'IntelOne Text';
                    font-weight: 400;
                    line-height: 22px;
                    word-wrap: break-word;
                    display: flex;
                    align-items: center;
                    gap: .5rem 
                }

            }
        }
    }
}
.hero-banner-button-content-text.cds--btn--secondary:hover {
    background-color: #16cfb1;
    border-color: var(--cds-spark-button-primary-hover);
}