@import '../../sassStyles/main.scss';
.main {
  width: 100%;
}

.hide {
  visibility: hidden;
}

.width100 {
  width: 100%;
}
.paddingRight30 {
  padding-right: 30px;
}

.sectionWrapper {
  background-color: var(--Layer-layer-01, #F9F9F9);
  padding: 32px;
  font-size: 16px;
  color: #262626;
  line-height: 20px;
  position: relative;
  overflow: hidden;
}

@media screen and (max-width: 1366px) {
  .sectionWrapper {

  }
}
.GridSelectionCustomStyle {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
}

@media screen and (min-width:1366px) {  
  .sectionWrapper{

  }
}

@media screen and (min-width:1440px) {  
  .sectionWrapper{


  }
}

@media screen and (min-width:1600px) {  
  .sectionWrapper{

  }
}
@media screen and (min-width:1920px) {  
  .sectionWrapper{

  }
}

@media screen and (min-width:2560px) {  
  .sectionWrapper{

  }
}

@media screen and (min-width:3440px) {  
  .sectionWrapper{

  }
}
