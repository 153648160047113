@use '@spark-design/carbon-theme';
@use '@carbon/styles/scss/type';
@use '@carbon/styles/scss/fonts';
@use '@carbon/themes/scss/themes';
// @import '@spark-design/fonts/fonts.css';
// @import '@spark-design/iconfont/dist.web/icons.css';
// @import '../../sassStyles/main.scss';

.regular-Body-M-Subtitle-S-font-size-75{
    color: var(--Text-text-secondary, #676D84);
    /* Regular/Body M | Subtitle S (font-size-75) */
    font-family: "IntelOne Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    :link{
        /* Regular/Body M | Subtitle S (font-size-75) */
        color: var(--Link-link-primary, #09857C);
        font-size: 14px;
        font-family: "IntelOne Text";
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
        word-wrap: break-word;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: auto;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
    }
}

.regular-Body-L-Subtitle-M-font-size-100{
    color: var(--Text-text-secondary, #676D84);
    /* Regular/Body L | Subtitle M (font-size-100) */
    font-family: "IntelOne Text";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.regular-Title-XS-Subtitle-L-font-size-200{
    color: var(--Text-text-primary, #1C223B);
    /* Regular/Title XS | Subtitle L (font-size-200) */
    font-family: "IntelOne Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
}
.external-link{
    color: var(--Aqua-Aqua-Shade-2, #08746E);
    font-family: "IntelOne Text";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    text-decoration-line: underline !important;
    text-decoration-style: solid !important;
    text-decoration-skip-ink: auto !important;
    text-decoration-thickness: auto !important;
    text-underline-offset: auto !important;
    text-underline-position: from-font;
}
@mixin spark-font-size-m {
    color: var(--Text-text-secondary, #6a6d75);
    font-family: 'IntelOne Text';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
    word-break: break-word;
  }
  
  .overview-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: auto;
    justify-content: center;
    border-bottom: 1px solid var(--Border-border-subtle-01, #e2e2e4);
    background: var(--inverse-white-black-white-in-light-themes, #fff);
  
    .back-section {
      display: flex;
      width: 100%;
      padding: 4px 0px;
      justify-content: center;
      align-items: center;
      max-width: 99rem;
  
      .back-button {
        display: flex;
        // padding: 0px 12px;
        align-items: center;
        flex: 1 0 0;
        width: 100%;
        height: auto;
        outline-color: var(--cds-spark-theme-link-primary);
  
        @media (max-width: 99rem) {
          padding-left: 32px;
        }
  
        .back-button-link {
          width: 60px;
          text-decoration: none;
          cursor: pointer;
        }
  
        .back-button-icon {
          width: 16px;
          color: var(--Aqua-Aqua-Shade-1, #0f9b8f);
        }
  
        .back-button-text {
          color: var(--Aqua-Aqua-Shade-1, #0f9b8f);
  
          /* Breadcrumb/Breadcrumb Text */
          font-family: 'IntelOne Text';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.16px;
        }
      }
    }
  }
  
  .cds--stack-horizontal.content-tags{
    display: block;
  }
  .overview-banner {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 20px;
    align-self: stretch;
    flex-wrap: wrap;
    inline-size: 100%;
    block-size: 100%;
    position: relative;
    overflow: hidden;
    width: 100%;
  
    .overview-banner-bg {
      background-color: rgb(4, 14, 53);
      position: absolute;
      z-index: -1;
      min-inline-size: 100%;
      min-block-size: 100%;
      block-size: 100%;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 200px;
  
      .overview-banner-bg-left {
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 0;
        min-block-size: 100%;
      }
  
      .overview-banner-bg-right {
        position: absolute;
        right: 0px;
        top: 0px;
        z-index: 0;
        min-block-size: 100%;
      }
    }
  
    .overview-banner-title-and-tags {
      display: flex;
      //min-width: 600px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      width: 100%;
      height: auto;
  
      .overview-banner-title {
        align-self: stretch;
        color: var(--Text-text-on-color, #fff);
  
        /* Regular/Title L (font-size-500) */
        font-family: 'IntelOne Display';
        font-size: 48px;
        font-style: normal;
        font-weight: 400;
        line-height: 64px; /* 133.333% */
        letter-spacing: -0.5px;
        word-break: break-word;
  
        @media (max-width: 540px) {
          font-size: 36px;
          line-height: 48px;
        }
      }
  
      .overview-banner-tags-section {
        display: flex;
        padding-bottom: 8px;
        align-items: center;
        align-content: center;
        gap: 4px;
        align-self: stretch;
        flex-wrap: wrap;
  
        .overview-banner-icon {
          position: relative;
          width: 18px;
          height: 18px;
          color: #ffffff;
  
          .overview-banner-tags-icon {
            top: 0px;
            left: 0px;
            position: absolute;
            justify-content: flex-start;
            display: flex;
            align-items: center;
          }
        }
  
        .overview-banner-tag-section {
          display: flex;
          height: var(--tagHeight, 18px);
          align-items: flex-start;
          border-radius: 1000px;
          background: var(--Carbon-carbon-tint-2, #e9e9e9);
  
          .overview-banner-tag-content {
            display: flex;
            height: var(--tagHeight, 18px);
            align-items: center;
            gap: 2px;
            border-radius: var(--Height, 24px);
  
            .overview-banner-tag-label-container {
              display: flex;
              //padding: 8px;
              flex-direction: column;
              justify-content: center;
              align-items: center;
  
              .overview-banner-tag-text {
                color: var(--Text-text-primary, #2b2c30);
                background: var(--Carbon-carbon-tint-2, #e9e9e9);
                font-family: 'IntelOne Text';
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
                letter-spacing: 0.32px;
                max-inline-size: 20rem;
              }
              .overview-banner-tag-text .cds--tag__label {
                overflow: visible;
              }
            }
          }
        }
      }
    }
  }
  
  .cds--tabs:not(.cds--tabs--contained)
    .cds--tabs__nav-item.cds--tabs__nav-item--selected {
    border-block-end-width: 0.125rem;
    border-block-end-color: var(--Aqua-Aqua, #16cfb1) !important;
    color: var(--cds-spark-tabs-default-nav-item-selected-color);
  }
  
  .cds--tabs:not(.cds--tabs--contained) .cds--tabs__nav-item {
    block-size: 2.5rem;
    border-block-end-color: #c6c6c6 !important;
    border-block-end-width: 0.0625rem;
    background-color: var(--cds-spark-tabs-default-nav-item-background-color);
    color: var(--cds-spark-tabs-default-nav-item-color);
  }
  
  .cds--dropdown__wrapper .cds--list-box {
    border-top: var(--Top, 0px) solid var(--Border-border-strong-01, #8b8e97) !important;
    border-right: var(--Right, 0px) solid var(--Border-border-strong-01, #8b8e97) !important;
    border-bottom: var(--Bottom, 1px) solid
      var(--Border-border-strong-01, #8b8e97) !important;
    border-left: var(--Left, 0px) solid var(--Border-border-strong-01, #8b8e97) !important;
    background: var(--Transparent-Transparent, #fff) !important;
    background-blend-mode: multiply !important;
  }
  
  .cds--list-box__menu-icon {
    justify-content: end;
  }
  
  .overview {
      img{
          width: 100%;
          height: 100%;
      }
    pre code {
      white-space: inherit;
    }
    .sb-show-main.sb-main-padded {
      padding: 0;
    }
  
    .cds--row:not(:first-child) {
      margin-top: var(--cds-spark-theme-spacing-07);
    }
  
    .section-top-gap {
      scroll-margin-top: var(--cds-spark-theme-spacing-10);
    }
  
    .screenreader {
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
      clip: rect(1px 1px 1px 1px);
      clip: rect(1px, 1px, 1px, 1px);
    }
  
    .cds--snippet--single pre {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: var(--cds-spark-theme-spacing-03);
      color: var(--cds-text-primary);
      background-color: var(--cds-layer-03);
    }
  
    .cds--btn.cds--copy-btn.cds--copy {
      background-color: var(--cds-layer-03);
      color: var(--cds-text-primary);
    }
  
    .cds--snippet .cds--btn.cds--copy-btn {
      --cds-spark-button-copy-hover: var(--background-hover);
    }
  
    .cds--snippet.cds--snippet--single {
      background-color: var(--cds-layer-03);
    }
  
    &.cds--content {
      padding-top: 2rem;
      background: var(--Layer-layer-01, #F7F7F8);
      padding: 0;
    }
  
    .header-banner {
      margin-block-start: var(--cds-spark-theme-spacing-09);
    }
  
    p,
    ul li {
      color: var(--Text-text-secondary, #6A6D75);
      word-wrap: break-word;
      word-break: break-word;
    }
    ol, ul {
      list-style: disc;
      margin-left: 5%;
    }
  
    .stack-card-controls i:not(:first-child) {
      margin-left: var(--cds-spark-theme-spacing-05);
    }
  
    nav.desktop-menu {
      display: flex;
      justify-content: flex-start;
    }
  
    nav.desktop-menu a.cds--btn {
      block-size: var(--cds-spark-theme-spacing-08);
      background-color: var(--spark-color-transparent);
      margin: 0 1px;
      border-bottom: 0.0625rem solid var(--cds-link-primary);
      border-top: 0;
      border-right: 0;
      border-left: 0;
      color: var(--cds-link-primary);
    }
  
    nav.desktop-menu a.cds--btn:hover {
      background-color: var(--spark-color-transparent);
      border-bottom: var(--cds-spark-theme-spacing-01) solid
        var(--cds-link-primary-hover);
      color: var(--cds-link-primary-hover);
    }
  
    @media (max-width: 50.5rem) {
      .stack-card-controls {
        display: none !important;
      }
  
      .mobile-menu {
        display: block !important;
      }
  
      nav.desktop-menu {
        display: none !important;
      }
  
      .hero-banner .gradient-box {
        padding-left: var(--cds-spark-theme-spacing-05);
      }
    }
  
    @media (min-width: 50.5rem) {
      .mobile-menu {
        display: none !important;
      }
  
      nav.desktop-menu {
        display: flex !important;
        padding-left: var(--cds-spark-theme-spacing-05);
      }
  
      .hero-banner .gradient-box {
        padding-left: var(--cds-spark-theme-spacing-05);
      }
    }
  
    @media (min-width: 78rem) {
      &.cds--content {
        padding: 2rem var(--cds-spark-theme-spacing-07) 0 var(--cds-spark-theme-spacing-07);
      }
  
      .hero-banner .gradient-box {
        padding-left: var(--cds-spark-theme-spacing-12);
      }
  
      .tab-visibility {
        display: none !important;
      }
    }
  }
  .overview_icon{
    -webkit-text-fill-color: #ffffff;
  }
  .cds--tag.overview_icon_tag{
    background-color: transparent;
  }