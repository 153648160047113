@import url("https://fonts.googleapis.com/css2?family=Material+Icons&display=swap");
@import '../../sassStyles/main.scss';
.paper,
.paperSelected {
  padding: 10px;
  margin-right: 8px;
  cursor: pointer;
  text-align: center;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: #fff;
  display: block;
  /* height: 34px; */
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #FFF;
  text-align: center;
  font-family: "IntelOne Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: -0.264px;
}

.paper {
  color: #5400C0 !important;
  text-align: center;
  font-family: "IntelOne Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.264px;
  border: 1px solid var(--Cobalt-cobalt-shade-3, #040E35);
  background-color: var(--Background-background, #FFF);
}

.paper:hover {
  background-color: #b4f0ff !important;
}

.paperDisabled {
  background-color: #e9e9e9 !important;
  color: #aeaeae !important;
}

.paperDisabled:hover {
  background-color: #e9e9e9 !important;
  cursor: auto;
}

.paperSelected {
  background-color: #5400C0 !important;
  background-repeat: no-repeat;
  color: #fff !important;
}

.paper:last-child {
  margin-right: 0px;
}

.root {
  flex-grow: 1;
  position: relative;
}

.subscriptionstyles {
  font-size: 12px;
  color: #262626;
  align-items: center;
  display: inline-flex;
  height: 20px;
  font-style: italic;
  padding-top: 17px;
}

.selectionTitle {
      min-width: 15%;
      color: #6B6E76;

      /* Regular/Body M | Subtitle S (font-size-75) */
      font-family: "IntelOne Text";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.25px;
      /* margin-right: 17px !important;
      display: flex !important;
      text-align: right; */
      margin:auto;
      /* padding-bottom: 8px; */
      display: flex;
      justify-content: space-between;
      gap: 8px;
}

.selectionWrapper {
  display: flex;
}

.selectionContent {
  width: 92%;
}

.btnWrapper {
  display: flex;
  padding: 16px;
  position: relative;
  vertical-align: top;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.btnWrapperOnCollapse {
  justify-content: flex-start;
  display: inline;
  padding: 52px 0px 0px 86.5px;
  position: relative;
  top: 12px;
  left: 21.3em;
}

.customizeDownloadBtn {
  color: #0071c5 !important;
  font-family: "Intel Clear";
  font-size: 16px;
  line-height: 21px !important;
  text-align: center;
  box-sizing: border-box;
  height: 40px;
  width: 252px;
  border: 1px solid #0068b5 !important;
  white-space: break-spaces;
  background-color: #fff !important;
  margin-left: 30px;
  border-radius: 2px;
}

.customizeDownloadBtn:hover {
  background-color: #004a86 !important;
  border: 1px solid #004a86 !important;
}

.customizeDownloadBtn:focus {
  box-shadow: none !important;
  background-color: #0068b5 !important;
  border: 2px solid #0068b5;
}

.downloadBtn {
  // color: #ffffff;
  // font-family: "Intel Clear";
  
  text-align: center !important;
  height: 40px;
  min-width: 115px;
  border-radius: 2px;
  opacity: 1;
  // background-color:#0F9B8F !important;
  background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
  /* border-color: #0068b5 !important; */
  justify-content: center;
  // font-weight: bold !important;
  margin-right: 16px;

  color: var(--Text-text-inverse, #FFF);

  /* Button/Button Large */
  font-family: "IntelOne Text";
  font-size: 16px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px !important; /* 125% */
}

.downloadBtn:hover {
  // background-color: #004a86 !important;
   background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
}

.downloadBtn:focus {
  box-shadow: none !important;
 // background-color: #0068b5 !important;
  border: 2px solid #fff;

  background: var(--Aqua-Aqua-Shade-1, #0F9B8F);
}

.unSelectedTile {
  display: none;
}

.CheckMarkImage {
  height: 60px;
}

.NoCheckMarkImage {
  display: none;
}

.downloadText {
  color: var(--Text-text-primary, #2B2C30);

  /* Regular/Title S (font-size-300) */
  font-family: "IntelOne Display";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
  /* margin-left: 21px; */
}

.RecommendedImage {
  width: 40px;
  height: 40px;
  border-radius: 25px;
}

.dottedLine {
  border-top: 1px dashed #548fad;
  bottom: 7px;
  margin-left: 62px;
  margin-right: 8px;
}

.GridSection {
  padding: 32px 0 0px 0px !important;
}

.TileSelectionTitleSpan {
  height: 21px;
}

.GridSection {
  padding: 32px 0px 32px 0px !important;
}

.TileSelectionTitleSpan {
  height: 21px;
}

.moduleSectionWrapper {
  position: absolute;
  width: 100%;
  transition: top 0.5s ease;
  padding: inherit;
  left: 0;
  
}

.ModulesAvailable {
  height: auto;
  min-height: 72px;
  background-color: white;
  // margin: 64px 0 0 0px;
  box-shadow: none;
  border: none;
  /* display: flex;
  justify-content: space-between; */
  display: flex;
  
  justify-content: space-between;
 
}

.ModulesAvailableText {
  // color: #262626;
  display: inline-flex;
  vertical-align: top;
  // font-size: 16px;
  width: 372px;
  // font-family: "Intel One medium";

  color: var(--Text-text-primary, #2B2C30);

  /* Regular/Body L | Subtitle M (font-size-100) */
  font-family: "IntelOne Text";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */

}

.EIDocumentLinks {
  display: flex;
  vertical-align: top;
  padding: 14px 0px 16px 0px;
  color: #0071c5 !important;
  font-size: 14px;
  font-family: "Intel Clear";
  padding-left: 45px;
  width: 292px;
  flex-direction: column;
  justify-content: center;
}

.EIDocumentLinksOnCOllapse {
  position: relative;
  top: 54px;
  display: inline-block;
  vertical-align: top;
  color: #0071c5 !important;
  font-size: 14px;
  font-family: "Intel Clear";
  left: -125px;
}

.EIDocColor {
  // color: #0071c5 !important;
  
   
    color: #08817B;

    /* Link/Small */
    font-family: "IntelOne Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */

    a:hover {
      text-decoration: underline !important;
      color: #08817B !important;
  }
}

.RecommendedTextOnCollapse {
  display: block;
  height: 18px;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
  top: 6px;
}

.DocumentTextOnCollapse {
  display: block;
  height: 18px;
  text-decoration: underline;
  cursor: pointer;
  text-align: right;
}

.DownArrowImg {
  width: 12px;
  height: 7px;
  // color: #0068b5 !important;
  padding: 26.5px 0px 28.59px 15px;
  cursor: pointer;
  color: var(--Text-text-primary, #2B2C30) !important;
}

.DownArrowImgOnCollapse {
  width: 12px;
  height: 7px;
  // color: #0068b5 !important;
  cursor: pointer;
  display: inline-flex;
  padding-left: 15px;
  padding-top: 71.5px;
  position: relative;
  top: 31px;
  color: var(--Text-text-primary, #2B2C30) !important;
}

.EIDocumentLinksText {
  // display: block;
  // height: 18px;
  // text-decoration: underline;
  cursor: pointer;
  display: flex;
  gap: 4px;
  text-decoration: none;

  .spark-icon-external-link {
    color: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
  }
}

.DocLinkImg {
  /* width: 14px !important;
  height: 13px !important;
  margin-right: 5.2px;
  flex-shrink: 0; */
  color: #0F9B8F !important;
}

.DocLinkImgCollapse {
  width: 14px;
  height: 13px;
  margin-left: 5.2px;
  color: #0F9B8F !important;
}

.VerticalLine {
  display: inline-block;
  border-left: 1px solid #f3f3f3;
  height: 43px;
  margin: 15.5px 0px 13.5px 36px;
  position: relative;
  left: 11px;
}

.VerticalLineDashed {
  display: inline-flex;
  border-left: 1px dashed #548fad;
  height: 40px;
  position: relative;
  top: 56px;
  left: -10.1em;
}

.ModuleList {
  width: 1000px;
  background-color: #f3f3f3;
  margin-left: 50px;
}

.ModulesAvailableTextSpan {
  padding: 28px 0px 24px 45px;
}

.OnlineDistributionLicenseTextSpan {

}
.SubscribeTextDiv {
  padding-left: 36.5px;
  background-color: #f3f3f3;
  margin-bottom: 24px;
}

.SubscribeCheckBox {
  display: inline-block;
  color: #0068b5 !important;
  padding-right: 4px;
}

.productKeyWrapper {
  // padding: 12px 39px 11px 19px;
  // display: inline-flex;
  // justify-content: flex-start;
  // width: 100%;
  // border: 1px solid #c9cace;
  // border-radius: 2px;
  // opacity: 1;
  // height: 40px;
  // align-items: center;

  padding: 12px 8px 11px 19px;
  display: inline-flex;
  width: 100%;
  border: 1px solid #c9cace;
  border-radius: 2px;
  opacity: 1;
  // height: 40px;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  max-height: fit-content;

}

.productKeyWrapper:hover {
  border: 1px solid #525252;
}

.modulesWrapper {
  color: #262626;
  font-size: 13px;
  font-family: "Intel Clear";
  overflow: auto;
  text-overflow:clip;
  white-space: nowrap;
  // min-width: -webkit-fill-available;
  max-width:90%;
}

.ODTextBox {
  display: inline-flex;;
  vertical-align: top;
  margin: 16px;
  flex-grow: 1;
  flex-direction: row-reverse;
  width: 100px;
}
.ODTextBoxWrapper {
  display: flex;
  flex-direction: column;
  vertical-align: top;
  margin: 16px;
  flex-grow: 1;
}
.ODTextBoxLineItem {
  display: inline-flex;
  vertical-align: top;
  margin: 16px;
  margin-top: 0px;
  flex-grow: 1;
  p{
    display: flex;
    max-width: 160px;
    align-self: center;
    margin: 16px;
    flex-grow: 1;
  }
}
.TextOverFlowHidden {
  word-break: break-all;
}

.ModulesAvailableTextOnCollpase {
  color: #262626;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  height: 20px;
}

#ExpandIcon {
  transform: rotate(180deg);
}

#CollapseIcon {
  transform: rotate(0deg);
  color: var(--Text-text-primary, #2B2C30) !important;
}

.infoIconStyle {
  color: #0071C5 !important;
  width: 16px !important;
  height: 16px !important;
}

.infoIconStyle:hover {
  color: #0071C5;
  background-color: rgba(112, 112, 112, 0.2);
  cursor: pointer;
  border: 1px solid rgba(112, 112, 112, 0.2);
}

.ModulesAvailableTextSpanOnCollpase {
  font-weight: bold;
  display: inline-flex;
  padding-top: 64px;
  padding-left: 24px;
}

.VerticalLineDashedOnCollapse {
  display: inline-flex;
  border-left: 1px dashed #548fad;
  height: 40px;
  margin-left: 15.5px;
  left: 15.5px;
  position: relative;
}

.buttonDisabled,
.buttonDisabled:hover,
.buttonDisabled:focus {
  background: #e9eaeb 0% 0% no-repeat padding-box !important;
  color: #b2b3b9 !important;
  border: #e9eaeb !important;
  cursor: not-allowed !important;
}

@media screen and (max-width: 1600px) and (min-width: 1366px) {
  .paper {
    text-align: center;
  font-family: "IntelOne Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.264px;
  background-color: #fff !important;
  background-repeat: no-repeat;
  color: #5400C0 !important;
  }

  .paperSelected {
    /* font-size: 14px !important; */
    // color: #5400C0 !important;
  text-align: center;
  font-family: "IntelOne Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.264px;
  background-color: #5400C0 !important;
  background-repeat: no-repeat;
  color: #fff !important;
  
  }
  .selectionTitle{
    width: 11%;
  }
  .selectionContent{
    width: 89%;
  }
}

@media screen and (min-width: 1920px) {
  .paper,
  .paperSelected {
    font-size: 14px !important;
    /* height: 80px; */
    /* padding: 30px 10px 10px 10px; */
  }
  

.SubscribeTextDiv {
  padding-left: 36.5px;
  background-color: #f3f3f3;
  margin-bottom: 24px;
  padding-bottom: 50px;
}
}

.CopyBtnDivNotVisible {
  display: inline-flex;
  padding: 4px;
  opacity: 0;
  position: absolute;
  display: none;
  transition: all 0.3s ease-out, background-position 1ms;
}
.OdLicenseAgreementTextDiv {
  display: flex;
  padding: 0px 30px 0px 0px;
  right: 16px;
  transition: all 0.3s ease-out;
  margin-top: 5px;
  bottom: 46px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  gap: 10px;
}
.OdLicenseAgreementTextDiv .btnWrapper{
  padding: 0px;
  flex-grow: unset;
}
.OdLicenseAgreementTextDiv .subscriptionstyles{
  position: static !important;
}
.OdLicenseAgreementText {
  /* font-size: 12px; */
  color: #262626;
  align-items: center;
  display: inline-flex;
  height: 20px;
  font-style: normal;
  padding-top: 17px;
  color: #0071c5 !important;
  font-family: intel clear;
}

.CopyBtnDiv {
  display: inline-flex;
  position: absolute;
  padding: 4px;
  right: 16px;
  opacity: 0;
  // transition: all 0.3s ease-out;
  margin-top: 5px;
}
.CopyBtnDiv:hover {
  opacity: 1;
  display: inline-flex;
  position: absolute;
  padding: 4px;
  right: 16px;
  margin-top: 0px;
}
.CopyBtnDiv:hover .productKeyWrapper {
  border: 1px solid #525252 !important;
}
.ODTextBox:hover .CopyBtnDiv {
  opacity: 1;
  margin-top: 0px;
}
.copyBtn,
.copyBtn:focus {
  width: 72px;
  height: 32px;
  // background: #0068b5 !important;
  border-radius: 2px !important;
  opacity: 1;
  font-size: 16px !important;
  color: #fff !important;
  text-transform: none !important;
  line-height: 0px;
  padding-top: 15px;
  // transition: all 0.01s ease-out;

  background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
}
.copyBtn:focus {
  box-shadow: none !important;
}
.copyBtn:hover .productKeyWrapper {
  border: 1px solid #525252 !important;
}
.CopyBtnDiv:hover .copyBtn {
  padding-top: 4px;
}

.ODTextBox:hover .copyBtn {
  padding-top: 4px;
  margin-right: 32px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.tooltipHeader {
  padding-right: 30px;
  display: inherit;
  font-size: 16px;
  font-family: "Intel One Medium";
}

.tooltip p {
  font-size: 12px;
}

.tooltip strong {
  font-size: 14px;
}

.tooltip div {
  font-size: 12px;
}

.closeBtn {
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

.closeBtn:hover {
  background-color: #252525;
  border-radius: 25% 25% 25% 25%;
}

.popoverOverlay {
  position: absolute !important;
  inset: auto auto 5px 0px !important;
  left: -25px !important;
}

.AvailableSection {
  // display: flex;
  // align-items: center;
  // gap: -32px;
  // flex: 1 0 0;
}

.material-icons {
  color: var(--Text-text-primary, #2B2C30) !important;
}

.spark-icon-external-link {
  color: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
}
.info-icon {
  color: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
}