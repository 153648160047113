.siteTitle {
font-family: IntelOne Display;
font-size: 48px;
font-weight: 400;
line-height: 64px;
letter-spacing: -0.5px;
text-align: left;

    color: #ffffff;
}

.SmallHeaderWrapper .siteTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    /* width: calc(60% - 1px); */
    white-space: nowrap;
}
.siteTitleDescription {
    color: #ffffff;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
}

.headerWrapper {
    /* height: 240px; */
    width: 100%;
    height: auto;
    min-height: 180px;
    /* background: #262626; */
}

.SmallHeaderWrapper {
    /* height: 185px; */
    width: 100%;
    height: auto;
    min-height: 180px;
    /* background: #262626; */
}

.headerTop {
    position: fixed;
    width: 100%;
    z-index: 100;
}

.headerStyles {
    color: #ffffff;
    font-family: Intel Clear;
    font-size: 20px;
    line-height: 26px;
}

.imgCSS {
    height: 40px;
    float: left;
}

.headerBanner {
    height: 60px;
}

.formControl {
    float: right;
    padding: 20px;
    display: inline-block;
}

.refLinks,
.refLinks:hover,
.refLinks:visited {
    margin-right: 1em;
    margin-top: 0.5em;
    display: inline-block;
    font-family: "Intel Clear";
    color: #ffffff;
    font-size: 12px;
}

.container {
    top: 48px;
    position: relative; 
}

.SmallHeaderWrapper .container {
    height: 140px;
    display: flex;
    justify-content: space-between;
}

.editFilters,
.editFiltersDisabled {
    width: 60px;
    height: 26px;
    background:transparent;
    border: 1px solid #ffffff;
    text-align: left;
    font: 12px "IntelOne Text";
    letter-spacing: 0px;
    color: #ffffff;
    padding: 8px 9px 8px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 16px;
    font-weight: 500;
    line-height: 12px;

}

.editFilters:active {
    background: #00285A 0% 0% no-repeat padding-box !important;
    border: 1px solid #00285A;
}

.editFilters:hover {
    background: #004a86 0% 0% no-repeat padding-box !important;
    color: #ffffff;
}

.editFiltersDisabled,
.editFiltersDisabled:hover {
    background: #E9EAEB 0% 0% no-repeat padding-box !important;
    color: #808080;
    cursor: not-allowed !important;
}

.containerBackground {
    height: 204px;
    background: #406a8f;
    top: 108px;
    position: fixed;
    width: 100%;
    background-size: cover;
}

.containerNew {
    margin-top: 0px;
    height: 128px;
    background: #0071c5;
    padding: 10px 0px 10px 92px;
    background-size: cover;
    line-height: 1.43;
}

.selectOption {
    color: #0071c5 !important;
    font-family: "Intel Clear" !important;
    font-size: 11px !important;
    line-height: 14px !important;
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: none;
}

.selectOption option {
    color: #000;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: auto;
    background-color: #ffffff;
}

.headerMenu {
    display: flex;
    align-items: center;
    padding-right: 48px;
}

.headerMenuTitle {
    display: flex;
    color: #0071c5;
    align-items: center;
}

.headerMenuIcon {
    width: 21px !important;
    height: 21px !important;
    padding: 0;
    margin: 0;
    margin-right: -10px !important;
    margin-left: -6px;
}

.intelBlueBar {
    display: inline-block;
}

@media only screen and (min-width: 800px) {
    #headerLogoContainer {
        margin: 12px 0 7px 0;
    }
}

.headerLogoContainer {
    float: left;
    display: inline-block;
    font-style: normal;
    width: 100%;
    position: relative;
    z-index: 25;
    margin: 0;
    background-color: #0071c5;
}

.headerLogoContainer a {
    color: #fff;
    width: 54px;
}

.logoLeft {
    float: left;
}

@media (max-width: 800px) {
    .logoLeft {
        padding-right: 0;
    }
}

.logoImg {
    background: #0071c5;
    line-height: 1;
    width: 70px;
    padding: 0;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70px;
}

@media (min-width: 611px) and (max-width: 800px) {
    .logoImg {
        background-size: 46px auto;
        width: 46px;
        height: 46px;
    }
}

@media (max-width: 610px) {
    .logoImg {
        width: 52px;
        height: 33px;
        background-size: 52px auto;
    }
}

header#header .intelBlueBar .headerLogoContainer span.logo-text {
    font-family: "intel clear", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: inline-block;
    max-width: 90px;
    position: relative;
    padding: 3px 0 0;
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
}

@media (min-width: 801px) {
    header#header .intelBlueBar .headerLogoContainer span.logo-text {
        font-size: 20px;
        line-height: 24px;
        max-width: 120px;
        padding-top: 4px;
    }
}

@media (min-width: 611px) and (max-width: 800px) {
    header#header .intelBlueBar .headerLogoContainer span.logo-text {
        font-size: 15px;
        line-height: 17px;
        padding: 4px 0 0 10px;
    }
}

@media (max-width: 610px) {
    header#header .intelBlueBar .headerLogoContainer span.logo-text {
        padding: 0 0 0 10px;
        font-size: 15px;
        line-height: 16px;
    }
}

@media (max-width: 401px) {
    header#header .intelBlueBar .headerLogoContainer span.logo-text {
        padding: 0 0 0 5px;
        max-width: 85px;
    }
}

header#header .intelBlueBar .headerLogoContainer span.logo-img-text {
    display: block;
    font-weight: 200;
    font-family: "intel clear", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.navLinkIcon a:hover {
    text-decoration: none;
}

.navLinkIcon a {
    color: #0071c5;
    position: relative;
    top: 3px;
}

.navLinkIcon {
    font-family: "Intel Clear";
    color: #0071c5;
    background: transparent;
    display: inline-block;
    border: 0;
    white-space: nowrap;
    padding: 20.5px 5px;
    margin: 0 7px;
    font-size: 10px;
}

.navLinkIcon:focus {
    border-left: 2px solid #2c71c5;
    border-right: 2px solid #2c71c5;
    border-top: 2px solid #2c71c5;
    border-bottom: 4px solid #00aeef;
    border-radius: 2px 2px 0px 0px;
    opacity: 1;
}

.navLinkIcon :focus {
    outline: none;
}

.navLinkIcon :hover {
    color: #003c71;
}

.nav-link-icon-lang {
    font-family: "Intel Clear";
    color: #0071c5;
    background: transparent;
    display: inline-block;
    border: 0;
    white-space: nowrap;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0 7px;
}

.nav-link-icon-lang:focus {
    outline: none;
}

.in,
.inprofile {
    display: block;
}

.collapse,
.collapseprofile {
    display: none;
}

.opacity0 {
    opacity: 0;
}

.opacity1 {
    opacity: 1;
}

.megaPanel {
    left: 0;
    position: absolute;
    width: 100%;
    box-shadow: 0px 1px 0px #00000029;
    opacity: 1;
    z-index: 1;
}

.megaPanel.in {
    visibility: inherit !important;
}

.megaPanel {
    top: 70px;
}

.megaPanel {
    background: #fff;
}

.megaPanel .megaPanelBody {
    padding: 34px 33px 8px;
}

.megaPanelWrap {
    border-bottom: 1px solid #d7d7d7;
    border-top: 1px solid #d7d7d7;
}

.megaPanelHeading {
    height: 22px;
    margin-bottom: 15px;
}

.headerNav14 {
    font-family: "Intel Clear Bold";
    font-size: 14px;
    line-height: 18px;
    color: #0071c5;
    margin: 0;
    text-decoration: none;
    line-height: 2;
    font-weight: bold;
    border-bottom: 1px solid #d7d7d7;
}

.flexContentWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 -16px;
}

.panelItem {
    width: 20%;
    padding: 0 16px;
    margin-bottom: 32px;
}

.listUnstyled {
    padding-left: 0;
    list-style: none;
}

.langOption {
    line-height: 2;
}

.langOption a:hover {
    text-decoration: none;
}

.nav14 {
    font-family: "Intel Clear";
    font-size: 14px;
    color: #0071c5;
    text-decoration: none;
    line-height: 2;
    cursor: pointer;
    font-family: "Intel Clear";
    color: #0071c5;
    background: transparent;
    display: inline-block;
    border: 0;
    white-space: nowrap;
    padding-left: 0px;
    padding-right: 0px;
}

.nav14:focus {
    outline: none;
}

.liaButtonPrimary {
    font-family: "Intel Clear";
    color: #fff;
    background-color: #0071c5;
    border-color: #0071c5;
    border-radius: 3px;
    font-size: 14px;
    line-height: 17.5px;
    padding: 8px 16px;
    font-weight: 400;
}

.liaButtonPrimary:hover {
    font-family: "Intel Clear";
    background-color: #003c71;
    border-color: #003c71;
    color: #fff;
    text-decoration: none;
}

.nav12 {
    font-family: "Intel Clear";
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
    padding-bottom: 8px;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 8px;
    margin-left: 0;
    padding-top: 0;
    color: #707070;
}

.panelRegion {
    font-family: "Intel Clear Bold";
    font-size: 14px;
    color: #707070;
    padding-bottom: 8px;
    border-bottom: 1px solid #d7d7d7;
}

.navLinkIconLabel {
    margin-top: 5px;
    height: 10px;
    padding-right: 25px;
    width: 10px;
    display: inline-block;
}

.navLinkIconLabel2 {
    margin-top: 5px;
    height: 10px;
    padding-right: 23px;
    width: 10px;
    display: inline-block;
}

.width15 {
    width: 15px !important;
}

.displayflex {
    display: flex;
}

.navLinkIconNew a:hover {
    text-decoration: none;
}

.navLinkIconNew a {
    color: #0071c5;
    position: relative;
    top: 3px;
    font-size: 10px;
}

.navLinkIconNew {
    font-family: "Intel Clear";
    color: #0071c5;
    background: transparent;
    display: inline-block;
    border: 0;
    white-space: nowrap;
    padding-top: 21.5px;
    padding-bottom: 21.5px;
    margin: 0 7px;
}

.navLinkIconNew:focus {
    border-left: 2px solid #2c71c5;
    border-right: 2px solid #2c71c5;
    border-top: 2px solid #2c71c5;
    border-bottom: 4px solid #00aeef;
    border-radius: 2px 2px 0px 0px;
    opacity: 1;
}

.navLinkIconNew :focus {
    outline: none;
}

.navLinkIconNew :hover {
    color: #0071c5;
}

.SupportPadding {
    padding-top: 20.5px;
    padding-bottom: 21.5px;
}

.logoutMargin {
    margin-top: 15px;
    margin-bottom: 25px;
}

.breadcrumbs {
    width: 100%;
    height: 39px;
    background-color: #fff;
    border: 1px solid #f3f3f3;
    padding: 11px;
    padding-left: 34px;
    box-shadow: 0px 1px 2px #00000029;
    opacity: 1;
}

.breadcrumbsLink {
    color: #0071c5 !important;
    font-family: Intel Clear;
    font-size: 12px !important;
}

.breadcrumbsLinkActive {
    color: #525252 !important;
    font-family: Intel Clear !important;
    font-size: 12px !important;
    font-weight: bold !important;
}

.selectionTitle {
    font-family: IntelOne Text;
    font-size: 11px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;
    color: var(--Text-text-inverse, #FFFFFF);

}

.selectionTile {
    width: 120px;
    padding: var(--v-padding) var(--h-padding) var(--v-padding) var(--h-padding);
    opacity: 0px;
    border: 1px solid var(--border-strong, #8B8E97);

    
}

.selectionTile .dropdownText{
    font-family: 'IntelOne Text';
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: var(--Text-text-inverse, #FFFFFF);
    width: 90px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
    margin: 5px 22px 5px 8px;

}

@media screen and (min-width: 1366px) {
    .headerWrapper {
        height: auto;
        min-height: 180px;
        width: 100%;
        /* background: #262626; */
    }
}

@media screen and (min-width: 1440px) {

    .headerWrapper {
        height: auto;
        min-height: 180px;
        width: 100%;
        /* background: #262626; */
    }

}

@media screen and (min-width: 1600px) {

    .headerWrapper {
        height: auto;
        min-height: 180px;
        width: 100%;
        /* background: #262626; */
    }
   
}

@media screen and (min-width: 1920px) {

    .headerWrapper {
        height: auto;
        min-height: 180px;
        width: 100%;
        /* background: #262626; */
    }
    
}

@media screen and (min-width: 2560px) {
    
}

@media screen and (min-width: 3440px) {
    
}


.RequestAccessButton {
    width: 204px;
    height: 32px;
    /* background-color: #0068b5 !important; */
    color: #ffffff !important;
    font: 14px "Intel Clear";
    border-radius: 0px !important;
    border: 1px solid white !important;
    /* Auto layout */

}

.RequestAccessButton:hover {
    background: #004a86 0% 0% no-repeat padding-box !important;
    color: #ffffff;
}

.RequestAccessButton:focus {
    background-color: #0068b5;
    border: 4px solid #fff;
    position: relative;
    box-shadow: none;
    color: #fff;
    border: 1px solid #0068b5;
    box-shadow: inset 0px 0px 0px 1px #fff;
}

.RequestAccessButtonDisabled {
    width: 204px;
    height: 32px;
    background: #F3F3F3 0% 0% no-repeat padding-box !important;
    opacity: 1;
    text-align: center;
    font: bold 14px/18px "Intel Clear" !important;
    letter-spacing: 0px;
    color: #808080 !important;
    text-transform: none !important;
    border-radius: 0 !important;
}

.SubscribeTextDiv {
    background: var(--Cobalt-cobalt-shade-2, #000864);
    position: relative;
    /* top: 64px; */
    height: 56px;
    font: italic 12px/16px Intel Clear;
    letter-spacing: 0px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
}

.SubscribeCheckBox {
    color: #0068b5 !important;
    padding: 0px !important;
    margin-right: 4px !important;
    background-color: var(--Cobalt-cobalt-shade-2, #000864) !important;
    width: 15px;
    height: 15px;
}

.approvalText {
    text-align: left;
    font: italic 14px/18px "Intel Clear";
    color: #FFFFFF;
    opacity: 1;
    align-self: center;
    padding-left: 19px;
}

.RequestAccessButtonDiv {
    display: flex;
    /* padding-top: 9px; */
    margin: 8px 10px 8px 32px;
}

.RequestAccessDialogTitle {
    font-size: 24px;
    color: #262626;
    padding: 35px 22px 0px 40px;
    font-family: "Intel One medium";
}

.RequestAccessDialogContent {
    font-size: 16px;
    color: #333333;
    padding: 6px 92px 0px 40px;
    font-family: Intel Clear;
    line-height: 24px;
}

.RequestAccessDialogEITitle {
    font-size: 16px;
    font-weight: bold;
    font-family: "Intel Clear";
    display: inline;
}

.RequestAccessDialogContentLine2 {
    padding-top: 20px;
}

.RequestAccessDialoSupportTitle {
    font-size: 20px;
    color: #262626;
    padding: 43px 0px 0px 40px;
    font-weight: bold;
}

.RequestAccessDialoSupportTitleContent {
    font-size: 16px;
    font-family: "Intel Clear";
    padding: 3px 0px 0px 40px;
}

.RequestAccessDialogcontactSupportTeam {
    color: #0071c5;
    text-decoration: underline;
    cursor: pointer;
}

.RequestAccessDialoSupportTitleDiv {
    padding-bottom: 43px;
}

.RequestAccessDialogCloseIcon {
    width: 16px;
    height: 16px;
    color: #262626;
    float: right;
    cursor: pointer;
}

.white-filter{
    filter: invert(85%) sepia(99%) saturate(178%) hue-rotate(246deg) brightness(116%) contrast(100%);
}

.optInText{
   color: var(--Text-text-on-color, #FFF);

/* Regular/Detail (font-size-25) */
font-family: "IntelOne Text";
font-size: 11px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 145.455% */
letter-spacing: 0.25px;
}

.logoContainerSeperator {
    font-family: IntelOne Display;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #FFFFFF;

}

.headerLogoLeft{
    display: flex;
    width: 303px;
    align-items: center;
    justify-content: space-between;
    margin-left: 32px;
}

.headerRight{
    width: 72px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
}

.skipToMainContent{
    width: 204px;
    height: 32px;
    background-color: #0F9B8F !important;
    color: #ffffff !important;
    font: 14px "Intel Clear";
    border-radius: 0px !important;
}

.tagContainer{
    display: flex;
    align-items: center;
}

.tagContainer .tags{
    margin: 0px 4px 0px 4px;
    height: 18px;
    background: #E9E9E9;
    padding: 0px 8px 0px 8px;
    border-radius: 24px;

    font-family: IntelOne Text;
font-size: 12px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.3199999928474426px;
text-align: left;
color: #2B2C30;
}

@media screen and (min-width: 0px) and (max-width: 1366px) {
    .container{
        flex-direction: column;
    }
}
