.filterSideBar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border: 1px solid var(--Gray-300, #E2E2E4);
    background: #FFF;
    // width: 350px;
    width: 100%;


    .filter-shell{
        padding-bottom: 16px;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        .filter-heading{
            display: flex;
            height:32px;
            padding: 8px 8px;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            .filter-heading-text{
            overflow: hidden;
            color: var(--Text-text-secondary, #6A6D75);
            text-overflow: ellipsis;
            }
        }
        .filter-items{
            display: flex;
            padding: 7px 16px 7px 24px;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            outline-color: var(--cds-spark-theme-link-primary);
            cursor: pointer;
            .filter-items-label{
                display: flex;
                align-items: center;
                gap: 12px;
                align-self: stretch;
            }
            .filter-items-label-text{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                flex: 1 0 0;

                overflow: visible;
                color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
                font-family: "IntelOne Text";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
                letter-spacing: 0.16px;
            }
           .filter-selected .filter-items-label-text{
                color: #161616;
            }
            .filter-not-selected .filter-items-label-text{
                color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
            }
            
        }
        .filter-items.filter-selected .filter-items-label-text{
            color: #161616;
        }
        .filter-items.filter-not-selected .filter-items-label-text{
            color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
        }
        .filter-divider{
            display: flex;
            padding: 0px 16px 7.5px 16px;
            align-items: center;
            align-self: stretch;
            /* Style */
            background: var(--Transparent-Transparent, #FFF);
            background-blend-mode: multiply;
        }
        .labelDiv{
            display: flex;
            padding: 7px 16px;
            align-items: center;
            gap: 24px;
            align-self: stretch;
            .label-text{
                /*layout*/
                height: 18px;
                flex: 1 0 0;
                /* typography*/
                overflow: hidden;
                color: var(--text-text-secondary, var(--Text-text-secondary, #6A6D75));
                text-overflow: ellipsis;
                white-space: nowrap;
                font-family: "IntelOne Text";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px; /* 112.5% */
                letter-spacing: 0.16px;
            }
        }
        .clear-filter {
            padding: 7.5px 16px;
            color: var(--Aqua-Aqua-Shade-1, #0F9B8F);

            /* Link/Small */
            font-family: "IntelOne Text";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 133.333% */
        }
          /* unvisited link */
        a.clear-filter:link,
        /* visited link */
        a.clear-filter:visited,
        /* mouse over link */
        a.clear-filter:hover,    
        /* selected link */
        a.clear-filter:active {
            text-decoration: none;
            cursor: pointer;
            color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
        }
    }
}
.divider{
    padding: 7.5px 16px;
}