.customDownloadList {
    margin: 0px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.customDownloadList::-webkit-scrollbar {
    width: 10px;
}

.customDownloadList::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 20px;
    border-radius: 10px;
}

.customDownloadList::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: #6d6d6d;
}

.downloadList {
    margin: 0 auto;
    display: flex;
    background-color: #fff;
    min-height: 310px;
}

.rows {    
    width: 100%;
}

.displayFlex{
    display: flex;
    padding-top: 40px;
    justify-content: space-between;
    padding-bottom: 40px;
}

.paddingtop27 {
    padding-right: 15px;
}

div.customModuleList>div:nth-of-type(odd) {
    background: #f3f3f3;
}

.moduleList{
    /* width: 85%;
    margin-left: auto;
    margin-right: auto; */
    width: 100%;
    padding: 16px;
}

.customModuleList{
    width: 100%;
}

.includedInThisRecipe {
    color: #252525;
    font-family: "Intel One Medium";
    font-size: 20px;
    line-height: 20px;
}

.recommendedConfig {
    color: #262626;
    font-family: "Intel One Medium";
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 11px;
    margin-top: 10px;
}

.listItem {
    font-family: "Intel Clear";
    font-size: 14px;
    color: #262626;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border: none;
}

.listItem:nth-child(odd) {
    background: #F3F3F3 !important;
}

.listItem:last-child {
    border-bottom: 0;
}

.listItemnew {
    font-family: "Intel Clear";
    font-size: 16px;
    color: #252525;
    margin-top: 8px;
    line-height: 24px;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listItemnew p {
    margin: 0 !important;
    padding-left: 16px;
}

.listItem p {
    margin: 0 !important;
    padding-left: 20px;
}

.infoIconStyle {
    color: #0071C5 !important;
    width: 16px !important;
    height: 16px !important;
}

.infoIconStyle:hover {
    color: #0071C5;
    background-color: rgba(112, 112, 112, 0.2);
    cursor: pointer;
    border: 1px solid rgba(112, 112, 112, 0.2);
}

.chinaWarning {
    font-size: 12px;
    margin-top: 2.5em;
    display: inline-block;
    font-family: Intel Clear;
    color: #666660;
}

.refLinks1 {
    margin-left: 0.5em;
    margin-right: 1em;
    margin-top: 0px;
    display: inline-block;
    font-family: "Intel Clear";
}

.launchIcon {
    height: 9px !important;
    width: 9px !important;
    margin-left: 3px;
}

.tooltipHeader {
    padding-right: 30px;
    display: inherit;
    font-size: 16px;
    font-family: "Intel One Medium";
}

.tooltip p {
    font-size: 12px;
}

.tooltip strong {
    font-size: 14px;
}

.tooltip div {
    font-size: 12px;
}

.closeBtn {
    position: absolute;
    right: 16px;
    top: 25px;
    cursor: pointer;
    width: 24px !important;
    height: 24px !important;
}

.closeBtn:hover {
    background-color: #252525;
    border-radius: 25% 25% 25% 25%;
}

.popoverOverlay {
    position: absolute !important;
    inset: auto auto 5px 0px !important;
}

.popoverGradientTop {
    height: 16px;
    position: absolute;
    width: 90%;
    top: 40px;
    background: transparent linear-gradient( 180deg, #55555500 0%, #555555c4 51%, var(--unnamed-color-555555) 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #555555 0%, #555555c4 51%, #55555500 100%) 0% 0% no-repeat padding-box;
}

.popoverGradientBottom, .popoverGradientBottomNoMargin {
    height: 16px;
    position: absolute;
    width: 90%;
    bottom: 75px;
    background: transparent linear-gradient( 180deg, #55555500 0%, #555555c4 51%, var(--unnamed-color-555555) 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(180deg, #55555500 0%, #555555c4 51%, #555555 100%) 0% 0% no-repeat padding-box;
}

.popoverGradientBottomNoMargin {
    bottom: 0px !important;
}

.refLinks, .refLinks:hover {
    margin-right: 1em;
    display: inline-block;
    font-family: "Intel Clear";
    border-color: 1px solid #cccccc;
    border-radius: 2px;
    font-size: 16px;
    color: #76DAFF;
}

.iconStyle {
    width: 13px !important;
}

.contentTitleWrapper {
    align-items: flex-end;
    margin-bottom: 10px;
    align-items: center;
}

@media screen and (max-width: 1280px) {
    .displayFlex {
        display: flex;
        padding-top: 40px;
        padding-left: 0;
    }
    .contentTitleWrapper {
        width: calc(25% - 27px);
        display: flex;
    }
}

@media screen and (min-width:1366px) {
    .contentTitleWrapper {
        width: calc(25% - 27px);
        display: flex;
    }
}

@media screen and (min-width:1440px) {
    .contentTitleWrapper {
        width: calc(25% - 27px);
        display: flex;
    }
}

@media screen and (min-width:1920px) {
    .contentTitleWrapper {
        width: 14.5%;
        display: flex;
    }
}

.productKeyWrapper {
    padding: 12px 12px 39px 19px;
    display: inline;
    justify-content: space-between;
    width: 472px;
    border: 1px solid #C9CACE;
    border-radius: 2px;
    opacity: 1;
    height: 40px;
}

.modulesWrapper {
    color: rgb(38, 38, 38);
    font-size: 16px;
}

.copyBtn, .copyBtn:focus {
    width: 72px;
    height: 32px;
    background: #0068B5 !important;
    border-radius: 2px !important;
    opacity: 1;
    font-size: 16px !important;
    color: #fff !important;
    text-transform: none !important;
    line-height: 0px;
}

.copyBtn:hover {
    background-color: #004a86 !important;
}

.copyBtn:focus {
    box-shadow: none !important;
}