.cds--header.global-header {
    display: flex;
    height: 64px;
    padding: 0px 16px 0px 32px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: var(--Cobalt-cobalt-shade-3, #040E35);
    box-shadow: 0px 1px 0px 0px rgba(255, 255, 255, 0.10);
    position: sticky;
    box-sizing: border-box;
    top: 0;

    .left-global-header {
        display: flex;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        width: auto;

        .brand-slot-global-header {
            display: flex;
            align-items: flex-start;
            gap: 10px;

            .logo {
                width: 150px;
                height: 44px;
            }
        }

        .separator-global-header {
            display: flex;
            height: var(--20px, 20px);
            align-items: center;
            gap: 10px;
            width: auto;

            .separator-text-global-header {
                color: var(--Aqua-Aqua, #16CFB1);
                /* Project Name */
                font-family: "IntelOne Display";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 100% */
                text-transform: capitalize;
            }
        }

        .project-name-global-header {
            display: flex;
            height: var(--20px, 20px);
            align-items: center;
            gap: 10px;
            width: auto;

            .home-link {
                text-decoration: none;
            }

            .project-name-text-header {
                color: var(--Text-Dark-Default, #FFF);

                /* Project Name */
                font-family: "IntelOne Display";
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px; /* 100% */
                text-transform: capitalize;
            }
        }



    }

    .center-header-menu-button {
        display: flex;
        height: 32px;
        flex-direction: column;
        align-items: flex-start;
        width: auto;

        .button-menu {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            width: auto;

            .button {
                display: flex;
                height: var(--Height, 32px);
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                width: auto;
                background: var(--Aqua-Aqua-Shade-1, #0F9B8F);

                .button-content {
                    display: flex;
                    padding: var(--v-padding, 8px) var(--h-padding, 8px);
                    align-items: center;
                    gap: var(--Gap, 4px);
                    align-self: stretch;

                    .button-content-text {
                        color: var(--Text-text-on-color, #FFF);
                        /* Button/Button Text */
                        font-family: "IntelOne Text";
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 16px; /* 114.286% */
                        letter-spacing: 0.16px;
                    }
                }
            }
        }
    }

    .right-global-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        width: auto;

        .action-button-slot {
            display: flex;
            align-items: center;
            gap: 8px;
            width: auto;
            height: auto;

            .action-button {
                display: flex;
                padding: 8px;
                justify-content: center;
                align-items: center;
                width: auto;
                height: auto;

                .help-circle-icon {
                    width: 16px;
                    height: 16px;
                    color: #FFFF;
                    text-decoration: none;
                }

                .help-icon-menuitem-section {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-start;
                    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

                    .help-icon-menuitem {
                        display: flex;
                        text-decoration: none;
                        height: var(--Height, 32px);
                        
                        width: 100%;
                       
                        flex-direction: column;
                        justify-content: center;
                        align-items: flex-start;
                        
                        align-self: stretch;
                        background: var(--Layer-layer-01, #F9F9F9);

                        .help-icon-content {
                            display: flex;
                            padding: var(--v-padding, 8px) 1rem;
                            align-items: center;
                            gap: 0.5rem;
                            
                            justify-items: flex-start;
                            width: 100%;

                            .help-icon-content-text {
                                overflow: hidden;
                                color: var(--Text-text-secondary, #6A6D75);
                                text-overflow: ellipsis;
                                display: inline-block;
                                /* Menu/Medium */
                                font-family: "IntelOne Text";
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 16px; /* 114.286% */
                            }
                        }
                    }

                }
            }
        }

        .profile {
            display: flex;
            align-items: flex-start;
            width: auto;
            height: auto;

            .profile-button {
                display: flex;
                height: 32px;
                padding: 8px;
                justify-content: center;
                align-items: center;
                width: auto;

                .icon-user {
                    width: 16px;
                    height: 16px;
                    color: #FFFF;
                }
            }
        }
    }
  
}

.ribbon {
    width: 16px;
height: 64px;
gap: 0px;
opacity: 0px;
background: var(--Cobalt-cobalt-shade-3, #040E35);
}

.cds--overflow-menu.cds--overflow-menu--open {
    background-color: unset;
}

.cds--overflow-menu.cds--overflow-menu--open:hover {
    background-color: unset;
}

.cds--overflow-menu:hover {
    background-color: unset;
}

.cds--overflow-menu-options::after {
    display: none !important;
}

.cds--overflow-menu-options__option .help-icon-content {
    display: block;
    align-items: center;
    gap: 0.5rem;
    
    justify-content: flex-start;
    width: 100%;

}

.cds--overflow-menu-options__option .help-icon-content-text {
    overflow: hidden;
    margin-left: 6px;
    color: var(--Text-text-secondary, #6A6D75);
    text-overflow: ellipsis;
    display: inline-block;
    /* Menu/Medium */
    font-family: "IntelOne Text";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
}

.cds--overflow-menu-options__option .help-icon-menuitem {
    display: flex;
    text-decoration: none;
    height: var(--Height, 32px);
    
    width: 100%;
   
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
    background: var(--Layer-layer-01, #F9F9F9);
}
.cds--overflow-menu-options__option a:hover{
    text-decoration:none !important;
}
.cds--overflow-menu-options__btn:hover *,
.cds--overflow-menu-options__option a:hover *{
    color: #161616
}

.cds--overflow-menu-options__option .support-link {
    font-size: 16px;
}

.hide-toggletip {

    .cds--popover {
        display: none;
    }
}

@import '../../sassStyles/main.scss';