.catalog-layout-grid{
    padding-inline: 0px !important;
    padding-block: 32px !important;
    background: var(--Layer-layer-01, #f9f9f9);

    @media (max-width: 99rem) {
        padding-inline: 32px !important;
    }
}

.catalog-banner-text {
    color: var(--Black-and-White-white, #FFF);

    /* Regular/Title XS | Subtitle L (font-size-200) */
    font-family: "IntelOne Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 140% */
    max-width: 880px;

    @media (max-width: 1056px) {
        font-size: 18px;
        max-width: 100%;
    }
}

