@import '../../sassStyles/main.scss';

.di-body {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
    align-self: stretch;

    .di-page-section {
        display: flex;
        height: 600px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        width: 100%;

        .di-page-title {
            color: var(--Text-text-primary, #2B2C30);

            /* Regular/Title S (font-size-300) */
            font-family: "IntelOne Display";
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            /* 133.333% */
        }

        .di-page-body {
            display: flex;
            height: 520px;
            align-items: flex-start;
            flex-shrink: 0;
            align-self: stretch;
            overflow-y: auto;
            background: #ffffff;

            .di-page-body-card-area {
                display: flex;
                // height: 438px;
                flex-direction: column;
                align-items: flex-start;
                flex: 1 0 0;

                .di-page-body-card-area-highlight-border {
                    height: 2px;
                    flex-shrink: 0;
                    align-self: stretch;
                    background: var(--Aqua-Aqua, #16CFB1);
                }

                .di-page-body-card-section {
                    display: flex;
                    // height: 436px;
                    padding: 2rem;
                    align-items: flex-start;
                    gap: 16px;
                    flex-shrink: 0;
                    align-self: stretch;

                    .di-page-body-card-text-content {
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        gap: 16px;

                        .di-page-body-card-title {
                            font-family: "IntelOne Display";
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px;
                            color: var(--Text-text-primary, #2B2C30);

                        }

                        .di-page-body-card-text {
                            align-self: stretch;
                            color: var(--Text-text-primary, #2B2C30);
                            font-family: "IntelOne Display";
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 28px;
                        }

                        .di-page-body-card-sub-text {
                            align-self: stretch;
                            color: var(--Text-text-primary, #2B2C30);
                            font-family: "IntelOne Display";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 14px;
                        }

                        .di-code-snippet {
                            margin: 12px 0px 0px 0px;
                            gap: 16px;
                        }

                        .di-page-body-card-info-text {
                            align-self: stretch;
                            color: var(--Text-text-primary, #2B2C30);
                            font-family: "IntelOne Text";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                            /* 142.857% */
                            letter-spacing: 0.25px;
                        }

                        .di-page-body-card-line-with-shadow {
                            border: none;
                            margin: 0px 0px 8px 0px;
                            width: -webkit-fill-available;
                            height: 1px;
                            background-color: var(--spark-color-theme-light-gray900);
                            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
                        }
                    }
                }

            }
        }

    }
}