.overview{
.sidebar-block {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    border: 1px solid var(--cds-spark-theme-border-subtle-00, #e9e9e9);
    background: var(--Layer-layer-02, #fff);
}
.sidebar-title {
    background: var(--Layer-layer-03, #f1f1f3);
    color: var(--cds-text-primary);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    border-top: 1px solid var(--cds-spark-theme-border-subtle-00, #e9e9e9);
}
.sidebar-content {
    padding-top: var(--cds-spark-theme-spacing-06);
    padding-bottom: var(--cds-spark-theme-spacing-07);
    padding-left: var(--cds-spark-theme-spacing-06);
    padding-right: var(--cds-spark-theme-spacing-06);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: var(--cds-spark-theme-spacing-06);
    flex: 1 0 0;
    align-self: stretch;
}
.sidebar-content-stack {
    display: flex;
    flex-direction: column;
    row-gap: var(--cds-spark-theme-spacing-03);
    max-width: 100%;
}
}