.content_megabar {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    .content_search{
        display: flex;
        width: 193px;
        align-items: center;
    }
    .content_sort_by{
        display: flex;
        width: 193px;
        flex-direction: column;
        align-items: flex-start;
        padding-right: 12px;
        .cds--list-box{
            border-left: 1px solid #8B8E97 !important;
            border-top: 1px solid #8B8E97 !important;
            border-right: 1px solid #8B8E97 !important
        }
    }

}