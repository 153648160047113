.pagenotfound{
    display: flex;
    font-family: "Intel Clear Pro";
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    margin: 2.5em 0 0 1.5em;
    padding-left: 55px;
}

.pagenotfound p{
    font-weight: 100;
    font-family: "Intel Clear";
    font-size: 14px;
}
.marginleft20{
	margin-left:20px;
}