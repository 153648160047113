.main {
  width: 100%;
  padding: 23px 34px 28px 136px;
  overflow: auto;
  background-color: white;
}

.downloadInfoContainer {
  padding: 0 20px 20px;
  background-color: #f3f3f3;
  color: #262626;
  font-size: 12px;
  line-height: 16px;
}

.downloadRecommendation {
  width: 100%;
  overflow: hidden;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 40px;
  text-align: center;
}

.downloadInstruction {
  color: #262626;
  font-family: "Intel Clear";
  font-size: 12px;
  font-weight: bold;
  line-height: 23px;
  line-height: 21px;
  margin: 18px 0px;
}

.downloadimage {
  width: 357px;
  height: 208px;
}
.DownloadHeaderWrapper {
  color: #262626;
  font: 20px/20px "Intel One Medium";
  padding-bottom: 21px;
  padding-right: 85px !important;
}

.headerWrapper {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
}

.headerText {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px dashed #548fad;
}

.sectionWrapper {
  padding: 0px 52px;
  font-size: 16px;
  color: #262626;
  line-height: 20px;
}

.iconWrapper {
  width: 40px;
  height: 40px;
  margin-right: 21px;
}

.ProgressBarSection4 {
  background-color: rgb(0, 199, 253);
  width: 46%;
  height: 28px;
  color: #fff;
  text-align: end;
  padding-right: 7px;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
}

.resourceDescription {
  letter-spacing: 0px;
  color: #262626;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  font-family: "Intel Clear";
}

@media screen and (max-width: 1366px) {
  .main,
  .DownloadHeaderWrapper {
    padding-left: 12px;
    padding-right: 24px;
  }
}

.GridSelectionCustomStyle {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
}

@media screen and (min-width: 1366px) {
  .main,
  .DownloadHeaderWrapper {
    padding-left: 12px;
    padding-right: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .main,
  .DownloadHeaderWrapper {
    padding-left: 85px;
    padding-right: 24px;
  }
}

@media screen and (min-width: 1600px) {
  .main,
  .DownloadHeaderWrapper {
    padding-left: 95px;
    padding-right: 24px;
  }
}

@media screen and (min-width: 1920px) {
  .main,
  .DownloadHeaderWrapper {
    padding-left: 114px;
    padding-right: 24px;
  }
}

@media screen and (min-width: 2560px) {
  .main,
  .DownloadHeaderWrapper {
    padding-left: 310px;
    padding-right: 24px;
  }
}

@media screen and (min-width: 3440px) {
  .main,
  .DownloadHeaderWrapper {
    padding-left: 634px;
    padding-right: 24px;
  }
}
.recommendedConfigTitle {
  font-family: "Intel Clear";
  line-height: 21px;
  margin: 18px 20px;
}
.recommendedConfigTitle a {
  color: #0068b5;
  font-size: 24px;
}
.padding-Left {
  padding-left: 54px;
}