@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../../sassStyles/main.scss';
.enterprise-ai-software-blade {
  display: flex;
  padding: 2rem;
  //flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Layer-layer-01, #f9f9f9);
  justify-content: center;
  width: 100%;

  .carousel-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    width: 100%;
    max-width: 99rem;
    justify-content: center;
    height: auto;
    .enterprise-ai-software-blade-title-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      width: 100%;
      .enterprise-ai-software-blade-title-content {
        color: var(--Text-text-primary, #2b2c30);

        /* Regular/Title S-M (font-size-350) */
        font-family: 'IntelOne Display';
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px; /* 125% */
        width: auto;
        height: auto;
      }

      .enterprise-ai-software-blade-title-section-link {
        display: flex;
        align-items: center;
        gap: 8px;
        background: --Transparent-Transparent;
        background-blend-mode: multiply;
        width: auto;
        height: auto;
        text-decoration: none;
        cursor: pointer;
        outline-color: var(--cds-spark-theme-link-primary);

        .enterprise-ai-software-blade-title-section-link-text {
          color: var(--Aqua-Aqua-Shade-1, #0f9b8f);

          /* Link/Large */
          font-family: 'IntelOne Text';
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 137.5% */
          width: auto;
          height: auto;
        }
        .enterprise-ai-software-blade-title-section-link-icon {
          width: 16px;
          height: 16px;
          fill: var(--Transparent-Transparent, rgba(255, 255, 255, 0));
          background-blend-mode: multiply;
          color: var(--Aqua-Aqua-Shade-1, #0f9b8f);
        }
      }
    }

    .enterprise-ai-software-blade-description-section {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      width: 100%;
      height: auto;

      .enterprise-ai-software-blade-description-text {
        flex: 1 0 0;
        color: var(--Text-text-secondary, #6a6d75);

        /* Regular/Title XS | Subtitle L (font-size-200) */
        font-family: 'IntelOne Display';
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px; /* 140% */
      }
    }

    .project-grid {
      display: flex;
      padding-top: 16px;
      padding-inline: 16px;
      align-items: flex-start;
      align-content: flex-start;
      gap: 16px;
      align-self: stretch;
      flex-wrap: wrap;
      overflow: visible;
      width: 100%;
      height: auto;

      
      @media (max-width: 99rem) {
        padding-inline: 0px;
    }

      
      .project-grid-left-arrow-section {
        display: flex;
        height: 294px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        color: #0f9b8f;

        .project-grid-left-arrow {
          width: 32px;
          height: 2rem;
          flex-shrink: 0;
        }
      }
      .project-grid-right-arrow-section {
        display: flex;
        height: 340px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        color: #0f9b8f;

        .project-grid-right-arrow {
          width: 32px;
          height: 2rem;
          flex-shrink: 0;
        }
      }
    }
  }
}

.slick-list {
  width: 100%;

  
}

.slick-initialized .slick-slide {
  display: flex !important;
}

.slick-slide > div {
  width: 100%;
  margin-right: 8px;
  margin-left: 8px;
}

.slick-prev > button {
  width: 32px;
  height: 2rem;
  flex-shrink: 0;
  color: #0f9b8f;
  gap: 10px;
}

.routerlink-text {
  text-decoration: none;
}

.slide-arrow {
  position: absolute;
  top: 50%;
  cursor: pointer;
}
.prev-arrow {
  left: -24px;
  width: 32px;
  height: 2rem;
  flex-shrink: 0;
  color: #0f9b8f;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex !important;
  font-size: 32px;
  gap: 10px;
  margin-left: 8px;
}
.next-arrow {
  right: -20px;
  height: 2rem;
  flex-shrink: 0;
  color: #0f9b8f;
  flex-direction: column;
  justify-content: center;
  justify-items: right;
  align-items: center;
  display: flex !important;
  font-size: 32px;
  gap: 10px;
  margin-right: 8px;
}

.spark-icon:not(.ui).spark-icon-light {
  font-weight: 300;
}

.slick-track .slick-active:last-of-type> div {
  margin-right: 0px;

}

.slick-track .project-tile {
  max-width: unset;
}


 
