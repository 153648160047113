@import '../../sassStyles/main.scss';

div.downloadList > div:nth-of-type(even),
div.customDownloadList > div:nth-of-type(even) {
  background: #f3f3f3;
}

.formCtrl,
.formCtrl:hover {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #252525 !important;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 2px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-align: left;
  box-shadow: none !important;
}

.formCtrl::after {
  color: #0071c5;
  float: right;
  margin-top: 10px;
}

.listIem {
  font-family: "Intel Clear";
  font-size: 16px;
  margin: 0.5em 0 0 0;
  color: #252525;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.downloadDetails .refLinks1 {
  margin: 1.125em 1.125em 1.125em 0 !important;
}

.includedInThisRecipe {
  color: #252525;
  font-family: "Intel Clear";
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin: 0 0 1.125em 0;
}

.downloadCustomTitle {
  /* color: #262626;
  font-family: "Intel One Medium";
  font-size: 20px;
  display: inline-block; */
  width: 100%;
  /* border-bottom: 1px dashed #548fad; */

  color: var(--Text-text-primary, #2B2C30);
  font-family: "IntelOne Display";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

.myCard {
  height: 45em;
  width: auto;
  position: relative;
  z-index: 1;
  background-color: #f7f8fa;
  flex-direction: row;
  flex-wrap: wrap;
  filter: grayscale(100%);
  top: 30px;
}

.myCard:after {
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 100%;
  bottom: -5rem;
}

.myCard:before {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.myCard.active {
  z-index: 3;
  opacity: 1;
  pointer-events: auto;
  filter: none;
  background-color: #fff;
}

.myCard .row {
  min-width: 100%;
  margin: 0;
}

.formField {
  display: inline-block;
  padding-top: 5px;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  width: 100%;
  margin-bottom: 0.8125rem !important;
  font-weight: bold;
}

.downloadbtnContainerFinal {
  margin: 11px 0px 19px 0px;
  width: 75%;
}

.downloadBtn {
  color: #ffffff;
  margin-top: 8px;
  font-family: "IntelOne Text";
  font-size: 14px !important;
  line-height: 16px !important;
  text-align: center !important;
  width: 172px !important;
  height: 32px !important;
  border-radius: 2px;
  opacity: 1;
  // background-color: #0068b5 !important;
  // border-color: #0068b5 !important;
  background: var(--Aqua-Aqua-Shade-1, #0F9B8F);
  border-color: var(--Aqua-Aqua-Shade-1, #0F9B8F);
  justify-content: center !important;
}

.downloadBtn:hover {
  
  background-blend-mode: multiply;
  // color: #ffffff;
  width: 172px;
  height: 32px;
}
.downloadBtn:focus {
  // background-color: #0068b5 !important;
  background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
  // border: 4px solid #fff;
  position: relative;
  box-shadow: none;
  width: 172px;
  height: 32px;
  // color: #fff;
  // border: 1px solid #0068b5;
  border: 1px solid var(--Aqua-Aqua-Shade-1, #0F9B8F);
  // box-shadow: inset 0px 0px 0px 1px #fff;
}
.downloadbox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

.downloadSubHeading {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.downloadSubHeadingDownload {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-left: 16px;
}

.pageIdentification {
  height: 16px;
  width: 49px;
  /* color: #9b9b9b;
  font-family: "Intel Clear";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px; */
  text-align: right;
  margin-top: 4px;

  color: #000;

  /* keyboardshortcut/Small */
  font-family: "IntelOne Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.pageIdentificationNew {
  height: 16px;
  width: 49px;
  /* color: #9b9b9b;
  font-family: "Intel Clear";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px; */
  text-align: right;
  margin-top: 6px;
  padding-right: 15px;

  color: #000;

  /* keyboardshortcut/Small */
  font-family: "IntelOne Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
}

.optionDescription {
  /* color: #555;
  font-family: "Intel Clear";
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px; */
  white-space: pre-line;

  color: var(--Text-text-primary, #2B2C30);

  /* Regular/Body M | Subtitle S (font-size-75) */
  font-family: "IntelOne Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.optionHeading {
  /* color: #0071c5;
  font-family: "Intel Clear";
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px; */
  float: left;

  color: var(--Text-text-primary, #2B2C30);

  /* Regular/Title XS | Subtitle L (font-size-200) */
  font-family: "IntelOne Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
}

.optionVersion {
  height: 20px;
  width: 115px;
  color: #999999;
  font-family: "IntelOne Text";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
  float: right;
  width: auto;
}

.optionVersionleft {
  color: #555555;
  font-family: "IntelOne Text";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  float: left;
  font-style: italic;
}

.tooltipHeader {
  padding-right: 20px;
  display: inherit;
  font-size: 16px;
  font-family: 'Intel One Medium';
}

.tooltip p {
  font-size: 12px;
  font-family: "Intel Clear" !important;
}

.tooltip strong {
  font-size: 14px;
}

.tooltip div {
  font-size: 12px;
}

.greyText {
  /* color: #252525;
  font-family: "Intel Clear";
  font-size: 16px; */
  width: 73%;
  /* line-height: 24px; */

  color: var(--Text-text-secondary, #6A6D75);

  /* Regular/Body S (font-size-50) */
  font-family: "IntelOne Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.25px;
}

.paddingleftright5 {
  padding-left: 5px;
  padding-right: 5px;
}

.downloadCardButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 4px;
}

.downloadCardButtonNew {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 4px;
  margin-right: 40px;
}

/*common.css*/

.ESHWrapper {
  min-height: 420px;
  /* background-color: #fff; */
  overflow: hidden;
  /*margin: 0 25px;*/
  margin: 0px 2%;
  margin-top: 2%;
  background-color: var(--Layer-layer-01, #F9F9F9);
}

.cardCarousel {
  /* align-items: center; */
  justify-content: center;
  position: relative;
  /* flex-direction: row; */
  display: inline-flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  /* max-width: 200px;*/
  overflow-y: hidden;
}

.myCard {
  height: 45em;
  width: auto;
  position: relative;
  z-index: 1;
  background-color: #f7f8fa;
  /* flex-basis: 25%; */
  flex-direction: row;
  flex-wrap: wrap;
  filter: grayscale(100%);
  top: 30px;
}

.myCard:after {
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 100%;
  bottom: -5rem;
}

.myCard:before {
  position: absolute;
  top: 50%;
  left: 50%;
  /*-webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);*/
  font-size: 3rem;
  font-weight: 300;
  color: #fff;
}

.active {
  z-index: 3;
  /*-webkit-transform: scale(1) translateY(0) translateX(0);
  transform: scale(1) translateY(0) translateX(0);*/
  opacity: 1;
  pointer-events: auto;
  filter: none;
  /*border: 1px #CCCCCC solid;*/
  background-color: #fff;
}

.row {
  min-width: 100%;
  margin: 0;
}

.card-carousel {
  /* align-items: center; */
  justify-content: center;
  position: relative;
  /* flex-direction: row; */
  display: inline-flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  /* max-width: 200px;*/
  overflow-y: hidden;
}

/*.myCard .downloadCard {
  min-width: 350px !important;
}*/

.subHead {
  text-align: left;
  margin: 0px;
  /* color: #252525;
  font-family: "Intel One Medium";
  font-size: 20px; */
  letter-spacing: 0;

  color: var(--Text-text-primary, #2B2C30);

  /* Regular/Title S (font-size-300) */
  font-family: "IntelOne Display";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 133.333% */
}

.subHeadNew {
  text-align: left;
  margin: 0px;
  width: 360px;
  color: #252525;
  font-family: "Intel One Medium";
  font-size: 20px;
  letter-spacing: 0;
}

.btnback {
  /* border: 1px solid #262626 !important;
  border-radius: 2px;
  font-size: 12px; */
  width: 48px;
  height: 24px;
  /* background: #fff;
  color: #525252;
  margin: 0 10px;
  font-weight: bold; */

  background: var(--Transparent-Transparent, #FFF);
  background-blend-mode: multiply;
  border: 1px solid var(--Aqua-Aqua-Shade-1, #0F9B8F);

  color: var(--Aqua-Aqua-Shade-1, #0F9B8F);

  /* Button/Button Small */
  font-family: "IntelOne Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  

}
.btnback:focus,
.btnnext:focus {
  background-color: #0068b5;
  border: 4px solid #fff;
  position: relative;
  box-shadow: none;
  color: #fff;
  border: 1px solid #0068b5;
  /* -moz-box-shadow: inset 0px 0px 0px 10px #f00; */
  box-shadow: inset 0px 0px 0px 1px #fff;
  
}
.btnback:hover,
.btnnext:hover {
  background: #004a86 0% 0% no-repeat padding-box !important;
  color: #ffffff;
}

.btnnext {
  border: 1px solid #00a3f6;
  background-color: #00a3f6;
  border-radius: 2px;
  font-size: 12px;
  width: 48px;
  height: 24px;
  color: #fff;
  position: relative;
  top: -1px;
}
.btnnext span {
  position: relative;
  left: -5px;
}

.cardWrapper {
  clear: both;
  border: 1px solid #0071c5;
  padding: 16px 85px 16px 16px;
  margin-top: 15px;
  /*padding: 25px;
  padding-right: 15px;*/
  box-sizing: border-box;
  position: relative;
}

.cardWrapper.selected {
  background-color: #f3fcff;
}

.cardWrapper.disabled {
  border: 1px solid #999999;
  background-color: rgba(247, 248, 250, 0.45);
}

.download-cardWrapper {
  clear: both;
  margin-top: 18px;
  box-sizing: border-box;
  position: relative;
}

.cardWrapper p {
  clear: both;
}

.cardWrapper h4 {
  font-size: 14px;
  margin: 0;
  color: #0071c5;
  font-weight: bold;
}

.infoIconBox {
  clear: both;
  position: absolute;
  right: 45px;
  /*bottom: 10px;*/
}
.CheckboxInput:checked {
  /* background: #0071c5; */
  background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
  color: #fff;
}
.CheckboxInput:checked:after {
  /* background-color: #0071c5;
  border: 1px solid #0071c5; */
  border-radius: 2px;

  background: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
  border: 1px solid var(--Aqua-Aqua-Shade-1, #0F9B8F);
}
.CheckboxInput:disabled {
  /* background: #0071c5; */
  background: #B2B3B9 !important;
  color: #fff;
  opacity: 0.6;
  pointer-events: none;
}
.cbox {
  clear: both;
  position: absolute;
  right: 36px;
  /* bottom: 10px; */
  margin-top: 60px;
}

.cbox input {
  margin: 0px;
  /* width: 20px;
  height: 20px; */
  width: 16px;
  height: 16px;
}

.cardMainWrapper {
  padding: 28px 24px;
}

@media only screen and (min-width: 1200px) {
  .cardMainWrapper {
    padding: 40px;
    padding-bottom: 35px;
  }
}

.cardMainWrapperWidth {
  padding: 28px 24px;
  flex: 0 0 100%;
  max-width: 100%;
}

.pullLeft {
  max-width: 55%;
}

@media only screen and (min-width: 1200px) {
  .pullLeft {
    max-width: 70%;
  }
}

@-webkit-keyframes slideInFromBottom {
  0% {
    transform: translateY(900px);
  }
  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes slideInFromBottom {
  0% {
    transform: translateY(900px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(900px);
  }
  100% {
    transform: translateY(0);
  }
}

.infoIconStyle {
  /* color: #0071c5 !important; */
  color: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
  width: 16px !important;
  height: 16px !important;
  background-color: unset;
  background-blend-mode: multiply;
}

.infoIconStyle:hover {
  /* color: #0071c5; */
  color: var(--Aqua-Aqua-Shade-1, #0F9B8F) !important;
  /* background-color: #f3f3f3; */
  cursor: pointer;
  border-radius: 5px;
}

.closeBtn {
  position: absolute;
  right: 16px;
  top: 25px;
  cursor: pointer;  
  width: 24px !important;
  height: 24px !important;
}

.closeBtn:hover {
  background: #004a86 0% 0% no-repeat padding-box !important;
  color: #ffffff;
}

.cardPadding {
  padding: 28px 24px;
}

@media only screen and (min-width: 1200px) {
  .cardPadding {
    /* padding: 28px 24px; */
    padding: 40px;
    padding-left: 24px;
  }
}

.fontsize20 {
  font-size: 8px !important;
}

.bgcolor {
  background-color: #f3f3f3;
}

.targetosform {
  font-size: 16px;
  font-family: Intel Clear;
  color: #252525;
}

.targetsystem {
  font-size: 16px;
  font-family: Intel Clear;
  color: #707070;
  padding-top: 18px;
}

.paddingtop10 {
  padding-top: 10px;
}

.subscriptionstyles {
  padding-left: 10px;
  margin-bottom: 0px;
  font-size: 12px;
  color: #525252;
  font-style: italic;
  font-family: 'IntelOne Text';
}
.subscriptionstylesDiv {
  height: 40px;
  margin-bottom: 8px;
}
.versionstyle {
  font-family: 'IntelOne Text';
  color: #252525;
  font-size: 16px;
  margin-bottom: 0px;
  line-height: 24px;
}

/* .pl2 {
  padding-left: .5rem!important;
} */

.downloadCardWrapper {
  clear: both;
  margin-left: 16px;
  box-sizing: border-box;
  position: relative;
}

.downloadCardWrapperNew {
  clear: both;
  margin-top: 18px;
  box-sizing: border-box;
  position: relative;
  width: 70%;
  margin: auto;
}

.popoverOverlay {
  position: absolute !important;
  inset: auto auto 5px 0px !important;
}

.downloadtext {
  // color: #252525;
  color: var(--Text-text-primary, #2B2C30);
  font-family: "IntelOne Text";
  font-size: 16px;
  margin-bottom: 0px;
}

.refLinks,
.refLinks:hover {
  margin-right: 1em;
  /* margin-top: 1.125em; */
  display: inline-block;
  font-family: "Intel Clear";
  border-color: 1px solid #cccccc;
  border-radius: 2px;
  font-size: 16px;
  color: #76daff;
}

.iconStyle {
  width: 13px !important;
  margin-left: 3px;
}

.popoverGradientTop {
  height: 16px;
  position: absolute;
  width: 90%;
  top: 40px;
  background: transparent
    linear-gradient(
      180deg,
      #55555500 0%,
      #555555c4 51%,
      var(--unnamed-color-555555) 100%
    )
    0% 0% no-repeat padding-box;
  background: transparent
    linear-gradient(180deg, #555555 0%, #555555c4 51%, #55555500 100%) 0% 0%
    no-repeat padding-box;
}

.popoverGradientBottom,
.popoverGradientBottomNoMargin {
  height: 16px;
  position: absolute;
  width: 90%;
  bottom: 75px;
  background: transparent
    linear-gradient(
      180deg,
      #55555500 0%,
      #555555c4 51%,
      var(--unnamed-color-555555) 100%
    )
    0% 0% no-repeat padding-box;
  background: transparent
    linear-gradient(180deg, #55555500 0%, #555555c4 51%, #555555 100%) 0% 0%
    no-repeat padding-box;
}

.popoverGradientBottomNoMargin {
  bottom: 0px !important;
}
.RecommendedImage {
  width: 40px;
  height: 40px;
  border-radius: 25px;
  margin: 0px 21px 0px 137px;
}
.CustomTitleMainDiv {
  padding: 32px 135.5px 7px 21px;
  display: flex;
  align-items: center;
}
.fontsizeForward {
  color: white;
  font-size: 8px !important;
  position: relative;
  top: -17px;
  left: 15px;
}
.ComponentsTitle {
  color: #252525;
  font-size: 20px;
  font-weight: bold;
  font-family: "IntelOne Text";
  margin-bottom: 10px;
  margin-left: 16px;
}
@media screen and (max-width: 1600px) and (min-width: 1366px) {
  .downloadBtn {
    margin-top: 30px !important;
  }
}

.cds--btn--secondary:hover {
  background-color: var(--cds-button-secondary-hover, var(--cds-spark-button-secondary-hover));
}

.custom-download-ingredient-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 16px;
}
